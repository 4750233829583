<div class="flex-1 relative sm:z-0 flex sm:overflow-hidden reservation-timeline">
    <div class="overflow-auto">
        <mbsc-eventcalendar #eventCalendar [view]="timelineView" [options]="calendarOptions"
            [scheduleEventTemplate]="eventTemplate" [data]="myEvents" [resourceTemplate]="myResourceTemplate">
        </mbsc-eventcalendar>

        <ng-template #eventTemplate let-data>
            <div class="md-timeline-template-event" [ngStyle]="{background: data.color}">
                <div class="md-timeline-template-event-cont">
                    <i class="fa-solid fa-truck mr-2 text-black ml-2"></i>
                    <span class="md-timeline-template-title">{{data.original.title}}
                        <i *ngIf="data.original.isHazard"
                            class="md-timeline-template-title-hazard fa-solid fa-radiation"></i>
                    </span>
                    <p class="md-timeline-template-time">
                        <span>{{data.start}}</span> -
                        <span>{{data.end}}</span>
                    </p>
                </div>
            </div>
        </ng-template>

        <ng-template #myResourceTemplate let-resource>
            <div class="md-work-week-cont">
                <div class="md-work-week-title" [ngStyle]="{color: resource.color}">{{resource.name}}</div>
            </div>
        </ng-template>

        <mbsc-popup class="md-tooltip h-full timeline-popup" #popup [anchor]="anchor" [options]="popupOptions">
            <div class="h-[460px]" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">

                <div class="md-tooltip-header" [ngStyle]="{background: bgColor}">
                    <span class="md-tooltip-name-age min-h-[10px] inline-block">{{info}}</span>
                    <span class="md-tooltip-time">{{time}}</span>
                </div>
                <div class="md-tooltip-info overflow-y-auto h-full w-full">
                    <div class="flex justify-around">
                        <div class="mt-3 mb-2 flex justify-center">
                            <button type="button" (click)="editAppointment(currentEvent?.id)"
                                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-default w-[140px] text-center">
                                <i class="fa-solid fa-pen sm:mr-2">
                                </i>
                                <span class="hidden md:inline-block">
                                    Edit
                                </span>
                            </button>
                        </div>

                        <div class="mt-3 mb-2 flex justify-center">
                            <button type="button"
                                (click)="confirmRemoveReservation(currentEvent?.equipmentId, currentEvent?.id)"
                                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-700 w-[140px] text-center">
                                <i class="fa-solid fa-trash sm:mr-2">
                                </i>
                                <span class="hidden md:inline-block">
                                    Delete
                                </span>
                            </button>
                        </div>

                    </div>

                    <div class="title">
                        <span class="block font-bold">Status:</span>

                        <span class="block relative mt-[-4pxpx]">
                            <span [style]="getStatusColor(currentEvent?.status)"
                                class="inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10">
                                {{currentEvent?.status ? reservationStatus[currentEvent?.status].text : 'Scheduled'}}
                            </span>
                        </span>
                    </div>

                    <div class="md-tooltip-title">
                        <span class="block font-bold">Unique Code:</span>
                        <span class="block relative text-gray-700 mt-[-12px]">{{currentEvent?.uniqueCode}}</span>
                    </div>

                    <div class="md-tooltip-title" *ngIf="currentEvent?.documents && currentEvent?.documents.length > 0">
                        <span class="block font-bold">File(s):</span>

                        <div class="container mx-auto px-5 py-2"
                            *ngIf="currentEvent?.documents && currentEvent?.documents.length > 0">
                            <div class="-m-1 flex flex-wrap md:-m-2">
                                <div *ngFor="let file of currentEvent?.documents, index as i"
                                    class="flex w-1/2 flex-wrap mt-2">
                                    <div class="w-full text-center relative mx-1">
                                        <a [href]="file.uri" target="_blank">
                                            <img *ngIf="isDocumentImage(file.name)"
                                                class="block w-full rounded-lg px-10 h-12 object-center"
                                                [src]="file.uri" />
                                            <img *ngIf="file.name?.includes('pdf')"
                                                class="block w-full rounded-lg px-10 h-12 object-center"
                                                [src]="'./assets/img/PDF_file_icon.svg'" />
                                            <img *ngIf="file.name?.includes('xls')"
                                                class="block w-full rounded-lg px-10 h-12 object-center"
                                                [src]="'./assets/img/excel.png'" />
                                            <img *ngIf="file.name?.includes('doc')"
                                                class="block w-full rounded-lg px-10 h-12 object-center"
                                                [src]="'./assets/img/word.svg'" />
                                            <img *ngIf="isFileImage(file.name)"
                                                class="block w-full rounded-lg px-10 h-12 object-center"
                                                [src]="'./assets/img/img-icon.png'" />
                                            <span
                                                class="leading-[14px] inline-block mt-2 text-[14px]">{{file.name}}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="md-tooltip-title">
                        <span class="block font-bold">Supplier:</span>
                        <span class="block relative text-gray-700 mt-[-12px]">{{currentEvent?.supplier}}</span>
                    </div>
                    <div class="md-tooltip-title">
                        <span class="block font-bold">Purchase order #:</span>
                        <span class="block text-gray-700 mt-[-10px]">{{currentEvent?.purcharseOrders}}</span>
                    </div>
                    <div class="md-tooltip-title">
                        <span class="block font-bold">Hazardous material:</span>
                        <span
                            class="block relative text-gray-700 mt-[-12px]">{{currentEvent?.hasHazardousMaterial}}</span>
                    </div>
                    <div class="md-tooltip-title">
                        <span class="block font-bold">Carrier: </span>
                        <span class="block text-gray-700 mt-[-10px]">{{currentEvent?.carrier}}</span>
                    </div>
                    <div class="md-tooltip-title">
                        <span class="block font-bold">Carrier Email: </span>
                        <span class="block text-gray-700 mt-[-10px]">{{currentEvent?.carrierEmails}}</span>
                    </div>
                    <div class="md-tooltip-title">
                        <span class="block font-bold">{{currentEvent?.carrierNo}}</span>
                        <span class="block text-gray-700 mt-[-10px]">124577</span>
                    </div>
                    <div class="md-tooltip-title">
                        <span class="block font-bold">Type of trailer:</span>
                        <span class="block text-gray-700 mt-[-10px]">{{currentEvent?.typeOfTrailer}}</span>
                    </div>
                    <div class="md-tooltip-title">
                        <span class="block font-bold">Nb pallets & dimensions:</span>
                        <span class="block text-gray-700 mt-[-10px]">{{currentEvent?.palletsInfo || 'N/A'}}</span>
                    </div>
                    <div class="md-tooltip-title">
                        <span class="block font-bold">Bill of lading #:</span>
                        <span class="block text-gray-700 mt-[-10px]">{{currentEvent?.billOfLanding || 'N/A'}}</span>
                    </div>

                    <div class="mt-8">
                        <img class="mx-auto h-20 w-20 rounded-md" [src]="currentEvent?.qrcodeUrl" alt="">
                    </div>


                </div>
            </div>
        </mbsc-popup>
    </div>
</div>