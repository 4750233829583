import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { ProfileService } from 'src/app/core/services/common/profile.service';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  [x: string]: any;

  private roles: string[] = [];
  private uid: string = '';
  private accessToken = '';
  private decodedToken: any = {};
  private helper = new JwtHelperService();

  constructor(
    private authService: MsalService,
    private profileService: ProfileService
  ) {}

  public setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  public getAccessToken() {
    return this.accessToken;
  }

  public readDataFromAzureToken() {
    const decodedToken = this.helper.decodeToken(this.accessToken);
    this.decodedToken = decodedToken;

    this.roles = this.decodedToken.roles || [];

    localStorage.setItem('SDT_USER_ROLE', JSON.stringify(this.roles));
  }

  public getRoles(): string[] {
    const rolesStr = localStorage.getItem('SDT_USER_ROLE');
    let roles = [];

    if (rolesStr) {
      roles = JSON.parse(rolesStr);
    }

    return roles;
  }

  public getUid(): string {
    return this.uid;
  }

  public isUserInRole(requiredRoles: any[]): boolean {
    const rolesStr = localStorage.getItem('SDT_USER_ROLE');
    let roles: any = [];

    if (rolesStr) {
      roles = JSON.parse(rolesStr);
    }

    return !requiredRoles ? true : requiredRoles.some((r) => roles.includes(r));
  }

  async fetchAccessToken() {
    let tokenResponse = await this.authService.instance.acquireTokenSilent({
      account: await this.authService.instance.getAllAccounts()[0],
      scopes: this.msalScopes,
    });

    this.setAccessToken(tokenResponse.accessToken);

    this.readDataFromAzureToken();

    this.profileService.updateToken(true);
  }
}
