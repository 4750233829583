import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnsavedChangesGuard } from '@app/guards/unsaved-changes.guard';
import { VmfLoginGuard } from '@app/guards/vmf-login.guard';
import { MsalGuard } from '@azure/msal-angular';
import { AdminComponent } from './features/admin/admin/admin.component';
import { CategoryComponent } from './features/admin/category/category.component';
import { RoleComponent } from './features/admin/role/role.component';
import { SiteComponent } from './features/admin/site/site.component';
import { AddCategoryComponent } from './features/admin/category/components/add-category/add-category.component';
import { EditMemberRoleComponent } from './features/admin/role/components/edit-member-role/edit-member-role.component';
import { AddSiteComponent } from './features/admin/site/components/add-site/add-site.component';
import { AddCrewComponent } from './features/admin/crew/components/add-crew/add-crew.component';
import { AddMemberComponent } from './features/admin/crew/components/add-member/add-member.component';
import { MembersDetailComponent } from './features/admin/crew/components/members-detail/members-detail.component';
import { CrewProfileComponent } from './features/admin/crew/components/crew-profile/crew-profile.component';
import { CrewComponent } from './features/admin/crew/crew.component';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { EquipmentComponent as EquipmentAdminComponent } from './features/admin/equipment/equipment.component';
import { EquipmentComponent } from './features/equipment/equipment.component';
import { AddEquipmentComponent } from './features/admin/equipment/components/add-equipment/add-equipment.component';
import { ReservationAddComponent } from './features/reservation/components/reservation-add/reservation-add.component';
import { ReservationComponent } from './features/reservation/reservation.component';
import { UnauthorizeComponent } from './features/unauthorize/unauthorize.component';
import { RoleGuard } from '@app/guards/role.guard';
import { FaqComponent } from './features/faq/faq.component';

const routes: Routes = [
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [VmfLoginGuard, MsalGuard],
    children: [
      {
        path: 'admin',
        canActivate: [RoleGuard],
        data: {
          requiredRoles: ['Global.Admin.ReadWrite.All'],
          requiredPatternRoles: ['Site.{siteId}.Manager.ReadWrite.All'],
          patternCode: [{ siteId: '@site.id' }],
        },
        children: [
          {
            path: '',
            component: AdminComponent,
          },
          {
            path: 'category',
            children: [
              {
                path: '',
                component: CategoryComponent,
              },
              {
                path: 'new',
                component: AddCategoryComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
              {
                path: 'modify/:id',
                component: AddCategoryComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
            ],
          },
          {
            path: 'role',
            children: [
              {
                path: '',
                component: RoleComponent,
              },
              {
                path: 'modify/:id',
                component: EditMemberRoleComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
            ],
          },
          {
            path: 'site',
            canActivate: [RoleGuard],
            data: {
              requiredRoles: ['Global.Admin.ReadWrite.All'],
            },
            children: [
              {
                path: '',
                component: SiteComponent,
              },
              {
                path: 'new',
                component: AddSiteComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
              {
                path: 'modify/:id',
                component: AddSiteComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
            ],
          },
          {
            path: 'reservation',
            children: [
              {
                path: '',
                component: ReservationComponent,
              },
              {
                path: 'new',
                component: ReservationAddComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
              {
                path: 'modify/:id',
                component: ReservationAddComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
            ],
          },
          {
            path: 'crew',
            children: [
              {
                path: '',
                component: CrewComponent,
              },
              {
                path: 'new',
                component: AddCrewComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
              {
                path: 'modify/:id',
                component: AddCrewComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
              {
                path: 'profile/:id',
                component: CrewProfileComponent,
              },
              {
                path: 'profile/:id/member/add',
                component: AddMemberComponent,
              },
              {
                path: 'profile/:crewId/equipment/add',
                component: AddEquipmentComponent,
              },
              {
                path: 'profile/:crewId/equipment/modify/:id',
                component: AddEquipmentComponent,
              },
              {
                path: 'profile/:crewId/:equipmentId/new',
                component: ReservationAddComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
              {
                path: 'profile/:id/members/detail',
                children: [
                  {
                    path: '',
                    component: MembersDetailComponent,
                  },
                  {
                    path: 'member/add',
                    component: AddMemberComponent,
                  },
                ],
              },
              {
                path: 'profile/:crewId/equipments/detail',
                children: [
                  {
                    path: '',
                    component: EquipmentComponent,
                  },
                  {
                    path: 'equipment/new',
                    component: AddEquipmentComponent,
                    canDeactivate: [UnsavedChangesGuard],
                  },
                  {
                    path: 'equipment/modify/:id',
                    component: AddEquipmentComponent,
                    canDeactivate: [UnsavedChangesGuard],
                  },
                  {
                    path: ':equipmentId/new',
                    component: ReservationAddComponent,
                    canDeactivate: [UnsavedChangesGuard],
                  },
                ],
              },
            ],
          },
          {
            path: 'equipment',
            children: [
              {
                path: '',
                component: EquipmentAdminComponent,
              },
              {
                path: 'new',
                component: AddEquipmentComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
              {
                path: 'modify/:id',
                component: AddEquipmentComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
              {
                path: 'duplicate/:id',
                component: AddEquipmentComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'faq',
        children: [
          {
            path: '',
            component: FaqComponent,
          },
        ],
      },
      {
        path: 'reservation',
        children: [
          {
            path: '',
            component: ReservationComponent,
          },
          {
            path: 'new',
            component: ReservationAddComponent,
            canDeactivate: [UnsavedChangesGuard],
          },
          {
            path: 'modify/:id',
            component: ReservationAddComponent,
            canDeactivate: [UnsavedChangesGuard],
          },
        ],
      },
      {
        path: 'equipment',
        children: [
          {
            path: '',
            component: EquipmentComponent,
          },
          {
            path: 'new',
            component: AddEquipmentComponent,
            canDeactivate: [UnsavedChangesGuard],
          },
          {
            path: 'modify/:id',
            component: AddEquipmentComponent,
            canDeactivate: [UnsavedChangesGuard],
          },
          {
            path: ':equipmentId/new',
            component: ReservationAddComponent,
            canDeactivate: [UnsavedChangesGuard],
          },
        ],
      },
      {
        path: 'crew',
        children: [
          {
            path: '',
            component: CrewComponent,
          },
          {
            path: 'new',
            component: AddCrewComponent,
            canDeactivate: [UnsavedChangesGuard],
          },
          {
            path: 'modify/:id',
            component: AddCrewComponent,
            canDeactivate: [UnsavedChangesGuard],
          },
          {
            path: 'profile/:id',
            component: CrewProfileComponent,
          },
          {
            path: 'profile/:id/member/add',
            component: AddMemberComponent,
          },
          {
            path: 'profile/:id/members/detail',
            component: MembersDetailComponent,
          },
          {
            path: 'profile/:crewId/equipment/add',
            component: AddEquipmentComponent,
          },
          {
            path: 'profile/:crewId/equipment/modify/:id',
            component: AddEquipmentComponent,
          },
          {
            path: 'profile/:crewId/:equipmentId/new',
            component: ReservationAddComponent,
            canDeactivate: [UnsavedChangesGuard],
          },
          {
            path: 'profile/:crewId/equipments/detail',
            children: [
              {
                path: '',
                component: EquipmentComponent,
              },
              {
                path: 'equipment/new',
                component: AddEquipmentComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
              {
                path: 'equipment/modify/:id',
                component: AddEquipmentComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
              {
                path: ':equipmentId/new',
                component: ReservationAddComponent,
                canDeactivate: [UnsavedChangesGuard],
              },
            ],
          },
        ],
      },
      {
        path: '',
        component: ReservationComponent,
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'unauthorize',
    children: [
      {
        path: '',
        component: UnauthorizeComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
