<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="[prevLink]" [showChevron]=true>
</app-page-title>
<article>
    <!-- Description list -->
    <div class="mt-24 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8  pb-[60px]">
        <div class="space-y-8 divide-y">
            <form [formGroup]="equipForm" (keydown.enter)="add($event)">

                <div class="shadow-xl drop-shadow-md  sm:rounded-md sm:overflow-hidden">
                    <div class="px-4 py-3 bg-yellow-default sm:px-6 text-black">
                        <h1 class="font-semibold">{{pageTitle}}</h1>
                    </div>

                    <div class="px-8 py-10 ">

                        <div>
                            <h3 class="text-lg leading-6 font-bold text-gray-900 mb-6">
                                <i class="fa-solid fa-users mr-2"></i>
                                {{ 'features.addEquipment.CREW_SECTION_TITLE'| translate }}

                            </h3>
                        </div>

                        <dl class="grid grid-cols-1 gap-x-24 gap-y-8 sm:grid-cols-2">

                            <div class="sm:col-span-1">
                                <mat-form-field [style.width.%]="100">
                                    <mat-label> {{ 'features.addEquipment.SELECT_CREW'| translate }}</mat-label>
                                    <mat-select [formControl]="crewCtrl" name="crew">
                                        <mat-option *ngFor="let crew of crews" [value]="crew.id">{{crew.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="crewCtrl.hasError('required')">
                                        {{ 'features.addEquipment.CREW_NAME_LABEL' | translate}} {{ 'labels.IS' |
                                        translate}} <strong>{{ 'labels.REQUIRED' |
                                            translate}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="sm:col-span-1">
                                <mat-form-field [style.width.%]="100">
                                    <mat-label>{{ 'features.addEquipment.CREW_MANAGER_NAME' | translate}}
                                    </mat-label>
                                    <input variant="outlined" matInput value="{{managers}}" [disabled]="true">
                                </mat-form-field>
                            </div>

                            <div class="sm:col-span-1">
                                <mat-form-field [style.width.%]="100">
                                    <mat-label> {{ 'features.addEquipment.SELECT_SHARE_CREW'| translate }}</mat-label>
                                    <mat-select [formControl]="ShareCrewCtrl" name="crew" multiple>
                                        <mat-option *ngFor="let crew of sharedCrews" [value]="crew.id">{{crew.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </dl>

                        <div>
                            <h3 class="text-lg leading-6 font-bold text-gray-900 mb-6 mt-8">
                                <i class="fa-solid fa-location mr-2"></i>
                                {{ 'features.addEquipment.EQUIPMENT_TRACKING'| translate }}
                            </h3>
                        </div>

                        <div class="mt-10">
                            <h3 class="text-lg leading-6 font-bold text-gray-900 mb-6">
                                <i class="fa-solid fa-screwdriver-wrench mr-2"></i>
                                {{ 'features.addEquipment.EQUIPMENT_DETAIL_TITLE'| translate }}
                            </h3>
                        </div>

                        <dl class="grid grid-cols-1 gap-x-24 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">

                                <mat-form-field [style.width.%]="100">
                                    <mat-label>{{ 'features.addEquipment.EQUIPMENT_NAME_LABEL' | translate}}
                                    </mat-label>
                                    <input variant="outlined" matInput
                                        placeholder="{{ 'features.addEquipment.EQUIPMENT_NAME_PROMPT' | translate}}"
                                        formControlName="name">
                                    <mat-error *ngIf="nameCtrl.hasError('required')">
                                        {{ 'features.addEquipment.EQUIPMENT_NAME_LABEL' | translate}} {{ 'labels.IS' |
                                        translate}} <strong>{{ 'labels.REQUIRED' |
                                            translate}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="sm:col-span-1">

                                <mat-form-field [style.width.%]="100">
                                    <mat-label>{{ 'features.addEquipment.SELECT_CATEGORY'| translate }}
                                    </mat-label>
                                    <mat-select [formControl]="categoryCtrl" name="categoryId">
                                        <mat-option *ngFor="let cat of categories" [value]="cat.id">{{cat.categoryName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="categoryCtrl.hasError('required')">
                                        {{ 'features.addEquipment.CATEGORY_NAME_LABEL' | translate}} {{ 'labels.IS' |
                                        translate}} <strong>{{ 'labels.REQUIRED' |
                                            translate}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="sm:col-span-1">
                                <mat-form-field [style.width.%]="100">
                                    <mat-label>{{ 'features.addEquipment.EQUIPMENT_UNIT_LABEL' | translate}}</mat-label>
                                    <input variant="outlined" matInput
                                        placeholder="{{ 'features.addEquipment.EQUIPMENT_UNIT_PROMPT' | translate}}"
                                        formControlName="unit">
                                    <mat-error *ngIf="unitCtrl.hasError('required')">
                                        {{ 'features.addEquipment.EQUIPMENT_UNIT_LABEL' | translate}} {{ 'labels.IS' |
                                        translate}} <strong>{{ 'labels.REQUIRED' |
                                            translate}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="sm:col-span-1">
                                <mat-form-field [style.width.%]="100">
                                    <mat-label>{{ 'features.addEquipment.EQUIPMENT_BRAND_LABEL' | translate}}
                                    </mat-label>
                                    <input variant="outlined" matInput
                                        placeholder="{{ 'features.addEquipment.EQUIPMENT_BRAND_PROMPT' | translate}}"
                                        formControlName="brand">
                                    <mat-error *ngIf="brandCtrl.hasError('required')">
                                        {{ 'features.addEquipment.EQUIPMENT_BRAND_LABEL' | translate}} {{ 'labels.IS' |
                                        translate}} <strong>{{ 'labels.REQUIRED' |
                                            translate}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="sm:col-span-1">
                                <mat-form-field [style.width.%]="100">
                                    <mat-label>{{ 'features.addEquipment.EQUIPMENT_MODEL_LABEL' | translate}}
                                    </mat-label>
                                    <input variant="outlined" matInput
                                        placeholder="{{ 'features.addEquipment.EQUIPMENT_MODEL_PROMPT' | translate}}"
                                        formControlName="model">
                                    <mat-error *ngIf="modelCtrl.hasError('required')">
                                        {{ 'features.addEquipment.EQUIPMENT_MODEL_LABEL' | translate}} {{ 'labels.IS' |
                                        translate}} <strong>{{ 'labels.REQUIRED' |
                                            translate}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>


                            <div class="sm:col-span-2">

                                <mat-form-field [style.width.%]="100">
                                    <mat-label>{{ 'features.addEquipment.EQUIPMENT_DESCRIPTION_LABEL' | translate}}
                                    </mat-label>
                                    <textarea matInput
                                        placeholder="{{ 'features.addEquipment.EQUIPMENT_DESCRIPTION_PROMPT' | translate}}"
                                        formControlName="description"></textarea>
                                </mat-form-field>

                            </div>

                            <div class="sm:col-span-2">
                                <mat-form-field [style.width.%]="100">
                                    <mat-label>Tags</mat-label>
                                    <mat-chip-list #chipList aria-label="Tag selection">
                                        <mat-chip *ngFor="let tag of tags" (removed)="removeTag(tag)">
                                            {{tag}}
                                            <button matChipRemove>
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip>
                                        <input placeholder="New tag..." #tagInput [formControl]="tagCtrl"
                                            [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            (matChipInputTokenEnd)="addTag($event)">
                                    </mat-chip-list>
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                                            {{tag}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-hint align="end">{{ 'features.addEquipment.TAG_HINT' | translate}}</mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="sm:col-span-2">
                                <div>
                                    <app-image-picker (fileChanged)="fileChanged($event)"></app-image-picker>
                                </div>
                                <div class="image-preview">
                                    <img [src]="equipImageUrlCtrl.value">
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <mat-slide-toggle color="green" labelPosition="before" formControlName="isActive">Active
                                </mat-slide-toggle>
                            </div>

                        </dl>
                    </div>

                    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button type="button" [routerLink]="['/admin/equipment/duplicate/' + equipId]" *ngIf="isEdit"
                            class="float-left relative inline-flex items-center px-4 py-2 border border-transparent shadow-lg text-sm font-medium rounded-md text-white bg-blue-darker hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-default">
                            <span> {{ 'features.equipment.DUPLICATE' | translate}}</span>
                        </button>

                        <button (click)="confirmRemoveEquipment()" *ngIf="isEdit"
                            class="float-left ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-red-700 hover:bg-red-800 focus:outline-none">
                            {{ 'features.crew.DELETE_CREW_BUTTON' | translate }}
                        </button>


                        <button (click)="cancel()"
                            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {{ 'labels.CANCEL' | translate}}
                        </button>

                        <button (click)="add()"
                            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600">
                            {{ 'labels.SAVE' | translate}}
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>

</article>