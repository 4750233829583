import { Component, OnInit, Input } from '@angular/core';
import { CrewService } from '@app/services/data/crew.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, takeUntil } from 'rxjs';
import { Crew } from 'src/app/data';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-crew-detail',
  templateUrl: './crew-detail.component.html',
  styleUrls: ['./crew-detail.component.scss'],
})
export class CrewDetailComponent extends SdtBaseComponent implements OnInit {
  @Input() editable: boolean = true;

  crewId!: number;
  crew!: Crew;
  editCrewLink: string = '/crew/modify/';
  prefixUrl = '/';

  constructor(
    private crewService: CrewService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    const crewId = this.route.snapshot.paramMap.get('id');

    if (this.router.url.includes('/admin')) {
      this.editCrewLink = `/admin/crew/modify/`;
    }

    if (crewId) {
      this.crewId = +crewId;
      this.editCrewLink += crewId;
      this.loadCrew();
    }
  }

  loadCrew() {
    this.crewService
      .getCrewById(this.crewId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((crew: Crew) => {
        this.crew = crew;
      });
  }

  getCrewManagers(managers: any) {
    const managerNames: any = [];
    managers.map((a: any) => {
      managerNames.push(a.name);
    });

    return managerNames.join(', ');
  }
}
