<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="[prevPageLink]" [showChevron]=true>
    <div content class="sm:hidden inline-flex">
        <button (click)="toggleLegend($event)" type="button"
            class="inline-flex absolute right-[14px] top-[12px] items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gray-800">
            <i class="fa-solid fa-circle-info"></i>
        </button>
    </div>
</app-page-title>
<div class="loading-spinner" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
<div class="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8 mt-24 mb-24">

    <div [ngClass]="{'block': isReservationOverlap, 'hidden': !isReservationOverlap}"
        class="sm:hidden w-full mx-auto py-3 px-3 fixed top-[114px] left-0 sm:px-6 lg:px-8"
        style="background: rgb(255, 0, 0, 0.8); z-index: 100;">
        <div class="pr-16 sm:text-center sm:px-16">
            <p class="font-medium text-white text-center ">
                <span class="rounded-lg bg-red-800 inline-block w-[26px] h-[26px] mr-2">
                    <i class="fa-solid fa-triangle-exclamation text-white"></i>
                </span>

                <span class="md:hidden"> {{ 'features.addReservation.RESERVATION_IN_CONFLICT' | translate}} </span>
            </p>
        </div>
    </div>

    <div class="mt-10">

        <dl class="space-y-10 md:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
            <div class="relative mb-10">
                <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                    <app-reservation-add-detail [isReservationOverlap]="isReservationOverlap"
                        [dateUpdated]="dateUpdated" (startDate)="selectStartDate($event)"
                        (selectUser)="selectUser($event)" (endDate)="selectEndDate($event)"
                        (recurringRule)="selectRecurringRule($event)" (equipment)="selectEquipment($event)"
                        (recurring)="recurring($event)" (updateLoading)="updateLoading($event)"
                        (reccurenceExclusions)="reccurenceExclusions($event)">
                    </app-reservation-add-detail>
                </div>

            </div>

            <div class="relative">
                <div class="bg-gray-50 shadow overflow-hidden sm:rounded-lg mb-8">
                    <app-reservation-add-availability [startDate]="startDate" [endDate]="endDate"
                        [recurringRule]="recurringRule" (isReservationOverlap)="reservationOverlap($event)"
                        (date)="udpateDate($event)" [equipment]="equipment" [selectedUser]="selectedUser"
                        [isRecurring]="isRecurring" [reccurenceExclusions]="reccurenceExclusionsArray">
                    </app-reservation-add-availability>
                </div>

                <app-reservation-calendar-legend [toggleReservationLegend]="toggleReservationLegend"
                    class="px-4 md:px-0"></app-reservation-calendar-legend>

            </div>
        </dl>

    </div>
</div>