<div class="relative px-4 py-2.5 sm:px-6 bg-yellow-default flex justify-between items-center flex-wrap sm:flex-nowrap">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
        <i class="fa-solid fa-circle-info mr-2"></i>
        {{ 'features.addReservation.RESERVATION_DETAIL' | translate}}
    </h3>
</div>

<form *ngIf="reservationform" [formGroup]="reservationform" class="reservation-form">
    <div class="px-6">

        <div class="flex mt-5 mb-5" *ngIf="isEdit">
            <span class="text-sm">{{ 'features.addReservation.UNIQUE_CODE' | translate}}:</span>
            <span class="ml-2 font-bold -mt-[2px]">{{reservation?.uniqueCode}}</span>
        </div>

        <div class="flex mt-10">
            <mat-form-field [style.width.%]="100" class="-mt-8 pr-2">
                <mat-label>{{ 'features.addReservation.STATUS' | translate}}</mat-label>
                <mat-select formControlName="status" name="status">
                    <mat-option *ngFor="let status of reservationStatus" [value]="status.key">{{status.text}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="equipmentCtrl.hasError('required')">
                    {{ 'labels.FIELD' | translate}} {{ 'labels.IS' |
                    translate}} <strong>{{ 'labels.REQUIRED' |
                        translate}}</strong>
                </mat-error>
            </mat-form-field>

        </div>

        <div class="flex mt-4">
            <mat-form-field [style.width.%]="100" class="-mt-8 pr-2">
                <mat-label>{{ 'features.addReservation.TYPE' | translate}}</mat-label>
                <mat-select formControlName="type" name="reservationType">
                    <mat-option *ngFor="let reservationType of reservationTypes"
                        [value]="reservationType.key">{{reservationType.text}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="reservationTypeCtrl.hasError('required')">
                    {{ 'labels.FIELD' | translate}} {{ 'labels.IS' |
                    translate}} <strong>{{ 'labels.REQUIRED' |
                        translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex mt-4">
            <mat-form-field [style.width.%]="100" class="-mt-8 pr-2">
                <mat-label>{{ 'features.addReservation.DOCK' | translate}}</mat-label>
                <mat-select [disabled]="reservationId !== undefined" formControlName="equipmentId" name="equipmentId"
                    (selectionChange)="onDockChange($event)">
                    <mat-option *ngFor="let dock of equipments" [value]="dock.id">{{dock.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="equipmentCtrl.hasError('required')">
                    {{ 'labels.FIELD' | translate}} {{ 'labels.IS' |
                    translate}} <strong>{{ 'labels.REQUIRED' |
                        translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex mt-4 mb-6">
            <div class="w-1/2 pr-3">
                <mat-form-field [style.width.%]="100" class="-mt-3">
                    <mat-label><i class="fa-solid fa-calendar-days mr-1"></i> {{
                        'features.addReservation.SELECT_START_DATE' | translate}}</mat-label>
                    <input mbsc-datepicker [mbscOptions]="datepickerOptionsStartDate" variant="outlined"
                        [formControl]="startDateCtrl" matInput>
                    <mat-error *ngIf="startDateCtrl.hasError('required')">
                        {{ 'features.addReservation.START_DATE' | translate}} {{ 'labels.IS' |
                        translate}} <strong>{{ 'labels.REQUIRED' |
                            translate}}</strong>
                    </mat-error>
                    <mat-error *ngIf="startDateCtrl.hasError('startdate_lesser_than_enddate')">
                        {{ 'features.addReservation.START_DATE_LESSER_THAN_EDN_DATE' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="w-1/2 pl-3">
                <mat-form-field [style.width.%]="100" class="-mt-3">
                    <mat-label><i class="fa-solid fa-calendar-days mr-1"></i> {{
                        'features.addReservation.SELECT_END_DATE' | translate}}</mat-label>
                    <input mbsc-datepicker [mbscOptions]="datepickerOptionsEndDate" variant="outlined"
                        [formControl]="endDateCtrl" matInput>
                    <mat-error *ngIf="endDateCtrl.hasError('required')">
                        {{ 'labels.FIELD' | translate}} {{ 'labels.IS' |
                        translate}} <strong>{{ 'labels.REQUIRED' |
                            translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="flex mt-[-30px]">
            <mat-form-field [style.width.%]="100" class="">
                <mat-label>Supplier</mat-label>
                <input variant="outlined" formControlName="supplier" matInput>

            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field [style.width.%]="100" class="">
                <mat-label>Purchase order number</mat-label>
                <input variant="outlined" formControlName="purcharseOrders" matInput>
                <mat-error *ngIf="purcharseOrdersCtrl.hasError('required')">
                    {{ 'labels.FIELD' | translate}} {{ 'labels.IS' |
                    translate}} <strong>{{ 'labels.REQUIRED' |
                        translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field [style.width.%]="100" class="">
                <input hidden=true matInput>
                <mat-checkbox matInput formControlName="hasHazardousMaterial">Delivery contains hazardous
                    material</mat-checkbox>
                <mat-error *ngIf="hasHazardousMaterialCtrl.hasError('required')">
                    {{ 'labels.FIELD' | translate}} {{ 'labels.IS' |
                    translate}} <strong>{{ 'labels.REQUIRED' |
                        translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field [style.width.%]="100" class="">
                <mat-label>Carrier</mat-label>
                <input variant="outlined" formControlName="carrier" matInput>
                <mat-error *ngIf="carrierCtrl.hasError('required')">
                    {{ 'labels.FIELD' | translate}} {{ 'labels.IS' |
                    translate}} <strong>{{ 'labels.REQUIRED' |
                        translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field [style.width.%]="100" class="">
                <mat-label>Carrier Email</mat-label>
                <input variant="outlined" formControlName="carrierEmails" matInput>
                <mat-error *ngIf="carrierEmailsCtrl.hasError('required')">
                    {{ 'labels.FIELD' | translate}} {{ 'labels.IS' |
                    translate}} <strong>{{ 'labels.REQUIRED' |
                        translate}}</strong>
                </mat-error>

                <mat-error *ngIf="carrierEmailsCtrl.hasError('pattern')">
                    Please enter a valid email address (separate by semi colon)
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field [style.width.%]="100" class="">
                <mat-label>Carrier trailer number</mat-label>
                <input variant="outlined" formControlName="carrierNo" matInput>
                <mat-error *ngIf="carrierNoCtrl.hasError('required')">
                    {{ 'labels.FIELD' | translate}} {{ 'labels.IS' |
                    translate}} <strong>{{ 'labels.REQUIRED' |
                        translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field [style.width.%]="100" class="">
                <mat-label>Type of trailer</mat-label>
                <input variant="outlined" formControlName="typeOfTrailer" matInput>
                <mat-error *ngIf="typeOfTrailerCtrl.hasError('required')">
                    {{ 'labels.FIELD' | translate}} {{ 'labels.IS' |
                    translate}} <strong>{{ 'labels.REQUIRED' |
                        translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field [style.width.%]="100" class="">
                <mat-label>Number of pallets and dimensions </mat-label>
                <textarea matInput formControlName="palletsInfo"></textarea>
                <mat-error *ngIf="palletsInfoCtrl.hasError('required')">
                    {{ 'labels.FIELD' | translate}} {{ 'labels.IS' |
                    translate}} <strong>{{ 'labels.REQUIRED' |
                        translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field [style.width.%]="100" class="">
                <mat-label>Bill of lading number </mat-label>
                <input variant="outlined" formControlName="billOfLanding" matInput>
            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field [style.width.%]="100" class="">
                <mat-label>Notes </mat-label>
                <textarea matInput formControlName="note"></textarea>
            </mat-form-field>
        </div>

        <div>
            <app-files-upload [reservation]="reservation" (filesToUpload)="filesToUpload($event)"></app-files-upload>
        </div>

        <div class="flex">
            <mat-form-field [style.width.%]="100" class="">
                <input hidden=true matInput>
                <mat-checkbox matInput formControlName="isClosed">Appointment closed</mat-checkbox>
            </mat-form-field>
        </div>

    </div>

    <div class="sm:shadow-none w-full left-0 fixed bottom-0  sm:relative px-4 py-3 bg-gray-50 text-right sm:px-6"
        style="z-index: 100; box-shadow: 0px -4px 10px -6px rgb(0 0 0 / 75%);">
        <button (click)="cancel()"
            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            {{ 'labels.CANCEL' | translate}}
        </button>

        <button (click)="confirmSave(false)"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 bg-green-700 hover:bg-green-800">
            {{'labels.SAVE' | translate}}
        </button>

        <button *ngIf="isEdit" (click)="confirmSave(true)"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 bg-green-700 hover:bg-green-800">
            Save and Email
        </button>

    </div>

</form>