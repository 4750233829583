<div [ngClass]="{'h-[312px] sm:h-auto': isShowLegend, 'h-0 sm:h-auto': !isShowLegend}"
    style="box-shadow: inset 0px 0px 5px 1px rgb(0 0 0 / 15%);"
    class="z-50 sm:shadow-none top-[118px] sm:top-0 left-0 bg-gray-200 sm:bg-white fixed sm:relative sm:mt-10 px-6 transition-height delay-150 duration-300 overflow-hidden w-full">
    <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide my-4 sm:my-0">{{
        'features.reservationLegend.CALENDAR_LEGEND' | translate}}</h2>
    <ul role="list" class="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-2 mt-3">

        <li class="relative col-span-1 flex shadow-sm rounded-md">
            <div
                class="flex-shrink-0 flex items-center justify-center w-8 bg-blue-default text-white text-sm font-medium rounded-l-md">
            </div>
            <div
                class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                <div class="flex-1 px-2 text-xs">
                    <div class="text-gray-900 text-center">{{ 'features.reservationLegend.NEW_RESERVATION' | translate}}
                    </div>
                </div>
                <div class="flex-shrink-0 pr-2">
                    <button type="button"
                        class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500"
                        id="pinned-project-options-menu-0-button" aria-expanded="false" aria-haspopup="true">

                    </button>
                </div>
            </div>
        </li>

        <li *ngFor="let status of reservationStatus" class="relative col-span-1 flex shadow-sm rounded-md">
            <div
                class="flex-shrink-0 flex items-center justify-center w-8 bg-[{{status.color}}] text-white text-sm font-medium rounded-l-md">
            </div>
            <div
                class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                <div class="flex-1 px-2 py-2 text-xs">
                    <p class="text-gray-900 text-center">{{status.text}}
                    </p>
                </div>
                <div class="flex-shrink-0 pr-2">
                    <button type="button"
                        class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500"
                        id="pinned-project-options-menu-0-button" aria-expanded="false" aria-haspopup="true">

                    </button>
                </div>
            </div>
        </li>

    </ul>

    <div class=" shadow absolute text-center left-0 right-0 bottom-0 sm:hidden m-auto cursor-pointer w-[200px] py-1 px-4 rounded-t-lg"
        style="background-color: #c8c8c8;" (click)="toggleLegend()">
        <i class="fa-solid fa-sliders mr-2"></i>
        <span>{{ 'features.addReservation.HIDE_LEGEND' | translate}}</span>
    </div>
</div>