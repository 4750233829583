import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CanComponentDeactivate } from '@app/guards/unsaved-changes.guard';
import { DialogService } from '@app/services/common/dialog.service';
import { SiteService } from '@app/services/data/site.service';
import { Observable, takeUntil } from 'rxjs';
import { Site } from 'src/app/data';
import { TranslateService } from '@ngx-translate/core';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import { MenuService } from '@app/services/common/menu.service';
import { Timezone as TZ } from 'src/app/data';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss'],
})
export class AddSiteComponent
  extends SdtBaseComponent
  implements OnInit, CanComponentDeactivate
{
  siteId!: number;
  isFormSaved: boolean = false;
  pageTitle = this.translate.instant('features.addsite.TITLE_HOME');
  siteManagers = this.translate.instant('features.site.SITE_MANAGER');
  sitePlanners = this.translate.instant('features.site.SITE_PLANNER');
  pageIconClass = 'fa-solid fa-circle-plus';
  siteForm!: FormGroup;
  timezones: TZ[] = [];

  constructor(
    private siteService: SiteService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private translate: TranslateService,
    private menuService: MenuService
  ) {
    super();
  }

  /**
   * Return true if the user has not made any changes still unsaved
   */
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.siteForm.dirty && !this.isFormSaved) {
      return this.dialogService.confirm(
        this.translate.instant('features.addsite.SITE_UNSAVED_CHANGES'),
        this.translate.instant('features.addsite.CONFIRM_CANCEL')
      );
    }

    return true;
  }

  ngOnInit(): void {
    this.buildSiteForm();

    this.loadTimezones();

    const siteId = this.route.snapshot.paramMap.get('id');

    if (siteId) {
      this.siteId = +siteId;
      this.pageIconClass = 'fa-solid fa-pen';
      this.pageTitle = this.translate.instant('features.addsite.TITLE_MODIFY');
      this.loadSite();
    }
  }

  /**
   * define the form for site entry
   */
  private buildSiteForm() {
    this.siteForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      code: ['', Validators.required],
      managerObjectIds: [[], Validators.required],
      planerObjectIds: [[], null],
      description: [''],
      sitecode: [this.site.code],
      timeOffset: ['', Validators.required],
    });
  }

  /**
   * Load timezones data.
   */
  loadTimezones() {
    this.siteService
      .getTimezones()
      .pipe(takeUntil(this.destroy$))
      .subscribe((timezones: any) => {
        this.timezones = timezones;
        console.log('timezones', timezones);
      });
  }

  /**
   *  Create or update a site
   */
  add(event: any = null) {
    event && event.preventDefault();

    this.siteForm.markAllAsTouched();

    if (this.siteForm.invalid) {
    } else {
      const site: Site = this.siteForm.getRawValue() as Site;

      console.log('site', site);

      site.managerObjectIds = site.managerObjectIds!.map((a: any) => a.id);
      site.planerObjectIds = site.planerObjectIds!.map((a: any) => a.id);

      this.siteService
        .saveSite(site)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.isFormSaved = true;
          this.goToSiteList();
          this.updateSiteMenu(site);
        });
    }
  }

  /**
   *
   */
  cancel() {
    this.goToSiteList();
  }

  /**
   * Get the form control for the site Name
   */
  get siteNameCtrl() {
    return this.siteForm.get('name') as FormControl;
  }

  /**
   * Get the form control for the site Time Zone
   */
  get timeZoneCtrl() {
    return this.siteForm.get('timeOffset') as FormControl;
  }

  /**
   * Get the form control for the code
   */
  get codeCtrl() {
    return this.siteForm.get('code') as FormControl;
  }

  /**
   * Load and set the site data to the form
   */
  loadSite() {
    if (this.siteId) {
      this.siteService
        .getSiteById(this.siteId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((site) => {
          this.siteForm.patchValue(site);

          const managers = site.managers!.map((a: any) => {
            return { id: a.objectId, displayName: a.name };
          });
          this.siteForm.controls['managerObjectIds'].patchValue(managers);

          const planers = site.planers!.map((a: any) => {
            return { id: a.objectId, displayName: a.name };
          });
          this.siteForm.controls['planerObjectIds'].patchValue(planers);
        });
    }
  }

  updateSiteMenu(site: Site) {
    if (site.code === this.site.code) {
      this.siteMenuService.updateSite(site);
      this.menuService.updateDefaultSite(true);
    }
  }

  /**
   * Return to site List
   */
  private goToSiteList() {
    this.router.navigate(['/admin/site']);
  }
}
