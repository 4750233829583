import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  modifyToggle: boolean = false;
  isSidenavCollapse: boolean = false;

  public updateSitesMenu: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public updateSite: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public toggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public toggleSidenav: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(private localStorageService: LocalStorageService) {
    this.isSidenavCollapse =
      this.localStorageService.get('SDT_SIDENAV_COLLAPSE') === 'true';

    if (this.isSidenavCollapse) {
      this.toggleSidenavMenu(true);
    }
  }

  toggleMenu(param: boolean): void {
    this.toggle.next(param);
  }

  getModifyToggle(): Observable<boolean> {
    return this.toggle.asObservable();
  }

  toggleSidenavMenu(param: boolean): void {
    this.localStorageService.set('SDT_SIDENAV_COLLAPSE', param);
    this.toggleSidenav.next(param);
  }

  getModifyToggleSidenav(): Observable<boolean> {
    return this.toggleSidenav.asObservable();
  }

  getToggleSidenav(): boolean {
    return this.isSidenavCollapse;
  }

  updateSites(param: boolean): void {
    this.updateSitesMenu.next(param);
  }

  getModifySites(): Observable<boolean> {
    return this.updateSitesMenu.asObservable();
  }

  updateDefaultSite(param: boolean): void {
    this.updateSite.next(param);
  }

  getModifyDefaultSite(): Observable<boolean> {
    return this.updateSite.asObservable();
  }
}
