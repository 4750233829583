<div class="relative transform overflow-hidden  bg-white px-4 text-left  transition-all sm:w-full sm:max-w-sm sm:p-6">
    <div>
        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Appointment saved</h3>
            <div class="mt-2">
                <p className="text-sm text-gray-500">
                    The confirmation number is:
                </p>
                <div class="text-base font-semibold leading-6 text-gray-900">{{data?.uniqueId}}</div>
            </div>
        </div>
    </div>
    <div class="mt-5 sm:mt-6">
        <button type="button" (click)="onNoClick()"
            class="inline-flex w-full justify-center rounded-md bg-blue-default px-3 py-2 text-sm font-semibold text-white shadow-sm">
            Close
        </button>
    </div>
</div>