import { Component, OnInit, ViewChild } from '@angular/core';
import { SdtBaseComponent } from '../SdtBaseComponent';
import { EquipmentService } from '@app/services/data/equipment.service';
import { EquipmentService as EquipService } from '@app/services/common/equipment.service';
import { Equipment } from 'src/app/data';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { MenuService } from '../../core/services/common/menu.service';
import { CrewService } from '@app/services/data/crew.service';
import { Crew, PaginatedItems } from 'src/app/data';
import { EquipmentSearchComponent } from './components/equipment-search/equipment-search.component';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss'],
})
export class EquipmentComponent extends SdtBaseComponent implements OnInit {
  @ViewChild('drawer') drawer!: MatSidenav;
  @ViewChild('equipSearch') equipSearchComponent!: EquipmentSearchComponent;

  pageIconClass = 'fa-solid fa-truck';
  pageTitle = 'Equipments';
  selectedEquipment!: Equipment;
  equipments!: Equipment[];
  crews: Crew[] | undefined;
  isAdmin = false;
  prevLink = '/home';
  showFiller = false;
  toggleSearchFilters = false;
  crewId: string = '';
  isSidenavCollapse = false;
  hasRightCreateEquipment = false;

  constructor(
    private equipmentService: EquipmentService,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private crewService: CrewService,
    private equipService: EquipService
  ) {
    super();

    this.menuService.getModifyToggleSidenav().subscribe((toggle) => {
      this.isSidenavCollapse = toggle;
    });

    this.equipService.getModifyEquipment().subscribe(() => {
      this.loadEquipments();
    });
  }

  ngOnInit(): void {
    this.crewId = this.route.snapshot.paramMap.get('crewId')!;

    if (this.router.url.includes('/admin/crew')) {
      this.isAdmin = true;
      this.prevLink = `/admin/crew/profile/${this.crewId}`;
    } else if (this.router.url.includes('/crew')) {
      this.prevLink = `/crew/profile/${this.crewId}`;
    }

    this.loadEquipments();
    this.loadCrews();
  }

  selectEquipment(equipment: Equipment) {
    this.equipmentService
      .getEquipmentById(equipment.id!, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.selectedEquipment = result;
      });
  }

  equipmentSearch(equipments: Equipment[]) {
    this.equipments = equipments;
  }

  loadEquipments() {
    this.equipmentService
      .getEquipments(this.site.code, 0, 0)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.equipments = result.data.filter((equip) => {
          return equip.isActive === true;
        });

        this.equipments.sort((a: Equipment, b: Equipment) => a.id! - b.id!);

        this.selectEquipment(this.equipments[0]);
      });
  }

  equipmentsSearch(equipments: Equipment[]) {
    this.equipments = equipments;
  }

  openDrawer(equipment: Equipment) {
    this.selectedEquipment = equipment;
    this.drawer.toggle();
  }

  closeDrawer() {
    this.drawer.toggle();
  }

  toggleFilters(event: any) {
    event.stopImmediatePropagation();

    this.toggleSearchFilters = !this.toggleSearchFilters;
  }

  addEquipment(event: any) {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  loadCrews() {
    // if there is no crew, that's mean we are no rights to create equipment
    this.crewService
      .getPaginatedCrewsByUser(
        this.site.code,
        this.profile.objectId,
        true,
        true,
        false,
        0,
        0
      )
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Crew>) => {
          return resp.data;
        })
      )
      .subscribe((crews) => {
        this.crews = crews;
        if (this.crews && this.crews.length > 0) {
          this.hasRightCreateEquipment = true;
        }
      });
  }
}
