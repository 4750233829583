import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  MbscEventcalendarOptions,
  MbscCalendarEvent,
  localeEn,
  localeFr,
} from '@mobiscroll/angular';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { Observable, takeUntil } from 'rxjs';
import { UserProfile } from 'src/app/data';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-detail-info',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss'],
})
export class ProfileDetailInfoComponent
  extends SdtBaseComponent
  implements OnInit, OnChanges
{
  constructor(
    private userProfileService: UserProfileService,
    private router: Router
  ) {
    super();
  }

  locale = this.profile.language === 0 ? localeEn : localeFr;
  userProfile!: UserProfile;
  myEvents: MbscCalendarEvent[] = [];

  @Input() member!: UserProfile | null;

  eventSettings: MbscEventcalendarOptions = {
    theme: 'ios',
    themeVariant: 'light',
    responsive: {
      xsmall: {
        view: {
          calendar: {
            type: 'month',
          },
          agenda: {
            type: 'day',
          },
        },
      },
      custom: {
        // Custom breakpoint
        breakpoint: 600,
        view: {
          calendar: {
            labels: false,
          },
        },
      },
    },
  };

  ngOnInit(): void {
    if (this.member) {
      this.loadUserProfile();
    } else {
      this.loadMyProfile();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadUserProfile();
  }

  loadUserProfile() {
    this.userProfile = this.member!;
  }

  loadMyProfile() {
    this.userProfileService
      .getMyUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: any) => {
        this.userProfile = userProfile;
      });
  }

  getLanguage(lang: number) {
    if (lang === 0) {
      return 'En';
    } else {
      return 'Fr';
    }
  }
}
