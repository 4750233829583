<div>
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div *ngIf='isOffCanvasMenuDialog' class="fixed inset-0 flex z-40 sm:hidden" role="dialog" aria-modal="true"
    style="z-index: 100000 !important;">
    <div @opacity *ngIf='isOffCanvasMenu' (click)="toggleOffCanvasMenu()"
      class="fixed inset-0 bg-gray-600 bg-opacity-75" aria-hidden="true"></div>
    <div @translateX *ngIf='isOffCanvasMenu'
      class="mt-16 relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-blue-default">
      <div @opacityInOut *ngIf='isOffCanvasMenu' class="absolute top-0 right-0 -mr-12 pt-2">
        <button type="button" (click)="toggleOffCanvasMenu()"
          class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
          <span class="sr-only">Close sidebar</span>
          <svg class="h-6 w-6 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex-shrink-0 flex items-center px-4">
        <img class="mx-auto w-auto h-[47px] absolute top-[11px] left-0"
          src="./assets/img/SDTS-Logo_horizontal-01-01.svg" />
      </div>
      <div class="mt-[45px] flex-1 h-0 overflow-y-auto">
        <app-sitenav-menu (closeCanvasMenu)="toggleOffCanvasMenu()"></app-sitenav-menu>
      </div>
    </div>

    <div class="flex-shrink-0 w-14" aria-hidden="true">
      <!-- Dummy element to force sidebar to shrink to fit close icon -->
    </div>
  </div>

  <!-- Static sidebar for desktop -->
  <div
    class="sidenav hidden sm:flex sm:w-14 sm:flex-col lg:flex sm:fixed sm:inset-y-0 lg:w-64 lg:flex-col lg:fixed lg:inset-y-0 z-50"
    style="z-index: 100001 !important;">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex flex-col flex-grow pt-5 bg-blue-default overflow-y-auto inner-sidenav">
      <div class="sidenav-title-content flex items-center flex-shrink-0 sm:pl-2 lg:px-4">
        <img *ngIf="!isSidenavCollapse" class="hidden lg:block mx-auto w-auto h-[42px] absolute top-[14px] left-0"
          src="./assets/img/SDTS-Logo_horizontal-01-01.svg" />
        <img *ngIf="isSidenavCollapse" class="hidden lg:block mx-auto w-auto h-[47px] absolute top-[11px] left-0"
          src="./assets/img/SDTS-Logo_icon-01.svg" />
        <img class="block lg:hidden mx-auto w-auto h-[47px] absolute top-[11px] left-[2px]"
          src="./assets/img/SDTS-Logo_icon-01.svg" />
      </div>
      <div class="mt-[45px] flex-1 flex flex-col bg-[#dedede]">
        <app-sitenav-menu></app-sitenav-menu>
      </div>
      <a (click)="toggleSidebarMenu()"
        class="hidden lg:block toggle-sidenav hover:bg-gray-300 pl-6 pt-3 pb-3 border-t-[1px] border-gray-700 bg-[#dedede]">
        <i class="collapse-icon font-bold w-4 h-4 fa-solid fa-chevron-left ml-1 mr-1"></i>
        <i class="expand-icon font-bold w-4 h-4 fa-solid fa-chevron-right ml-0" style="display: none;"></i>
        <span class="collapse-text">
          {{ 'labels.COLLAPSE' | translate }}
        </span>
      </a>
    </div>
  </div>