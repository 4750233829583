<article aria-label="File Upload Modal" class="mt-7 relative h-full flex flex-col" (drop)="dropHandler($event)"
    (dragover)="dragOverHandler($event);" (dragleave)="dragLeaveHandler($event);">

    <div class="">
        <label for="fileUpload" class="mb-2 block font-medium text-gray-500">Upload file(s)</label>
        <label
            class="flex w-full cursor-pointer appearance-none items-center justify-center rounded-md border-2 border-dashed border-gray-200 p-6 transition-all hover:border-primary-300">
            <div class="space-y-1 text-center">
                <div class="mx-auto inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="h-6 w-6 text-gray-500">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                    </svg>
                </div>
                <div class="text-gray-600">
                    <a href="javascript:void(0)"
                        class="pointer-events-none font-medium text-primary-500 hover:text-primary-700">Click
                        to upload</a>
                </div>
                <p class="text-sm text-gray-500">SVG, PNG, JPG, PDF, DOC, XLS (max. 10Mb)</p>
            </div>
            <input (change)="importFiles($event)" #fileUpload id="fileUpload" type="file" class="sr-only" accept="application/msword, application/vnd.ms-excel, .xls, .xlsx, .docx, .txt
            text/plain, application/pdf, image/*" multiple="multiple" />
        </label>
    </div>

    <div *ngIf="(documents && documents.length > 0) || (files && files.length > 0)" class="container mx-auto px-5 pt-8">
        <div class="-m-1 flex flex-wrap ">

            <div *ngFor="let file of documents, index as i" class="flex w-1/3 flex-wrap mt-2">
                <div class="w-full text-center relative">
                    <i (click)="removeSavedFile(file.id)"
                        class="cursor-pointer fa-solid fa-circle-xmark absolute right-5 -mr-[10px] top-0 text-[20px] text-gray-500 hover:text-black"></i>
                    <img *ngIf="isDocumentImage(file.name)" class="block w-full rounded-lg px-10 h-12 object-center"
                        [src]="file.uri" />
                    <img *ngIf="file.name?.includes('pdf')" class="block w-full rounded-lg px-10 h-12 object-center"
                        [src]="'./assets/img/PDF_file_icon.svg'" />
                    <img *ngIf="file.name?.includes('xls')" class="block w-full rounded-lg px-10 h-12 object-center"
                        [src]="'./assets/img/excel.png'" />
                    <img *ngIf="file.name?.includes('doc')" class="block w-full rounded-lg px-10 h-12 object-center"
                        [src]="'./assets/img/word.svg'" />
                    <span class="leading-1 inline-block mt-2 text-[14px]">
                        <a class="hover:underline" [href]="file.uri" target="_blank">{{file.name}}</a>
                    </span>
                </div>
            </div>

            <div *ngFor="let file of files, index as i" class="flex w-1/3 flex-wrap mt-2">
                <div class="w-full text-center relative">
                    <i (click)="removeFile(i)"
                        class="cursor-pointer fa-solid fa-circle-xmark absolute right-5 -mr-[10px] top-0 text-[20px] text-gray-500 hover:text-black"></i>
                    <img *ngIf="file.type.includes('image')" alt="gallery"
                        class="block w-full rounded-lg px-10 h-12 object-center"
                        [src]="file.imageUrl || './assets/img/img-icon.png'" />
                    <img *ngIf="file.type.includes('pdf')" alt="gallery"
                        class="block w-full rounded-lg px-10 h-12 object-center"
                        [src]="'./assets/img/PDF_file_icon.svg'" />
                    <img *ngIf="file.type.includes('xls')" alt="gallery"
                        class="block w-full rounded-lg px-10 h-12 object-center" [src]="'./assets/img/excel.png'" />
                    <img *ngIf="file.type.includes('officedocument')" alt="gallery"
                        class="block w-full rounded-lg px-10 h-12 object-center" [src]="'./assets/img/word.svg'" />
                    <span class="leading-1 inline-block mt-2 text-[14px]">
                        {{file.name}}
                    </span>
                </div>
            </div>

        </div>
    </div>
</article>