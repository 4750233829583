<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="['/admin']" [showChevron]=true>
</app-page-title>
<div class="loading-spinner" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <div class="space-y-8 divide-y divide-gray-200">
        <div>
            <button type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 float-right mb-8"
                (click)="addEquipment()">
                <i class="fa-solid fa-plus mr-2"></i>
                {{ 'features.equipment.ADD_EQUIPMENT_BUTTON' | translate }}
            </button>
        </div>

        <table mat-table [dataSource]="dataSource" class="cat-table">
            <!-- Position Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.equipment.COLUMN_EQUIPMENT'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{element.name}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="categoryName">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.equipment.COLUMN_CATEGORY'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{element.categoryName}}</span>
                </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.category.COLUMN_CAT_DESC'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element" class="p-3 pl-0">
                    <span class="text-sm">{{element.description}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.category.COLUMN_CAT_ACTION'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" [routerLink]="['duplicate/' + element.id]"
                        class="mt-3 relative inline-flex items-center px-4 py-2 border border-transparent shadow-lg text-sm font-medium rounded-md text-white bg-blue-darker hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-default">
                        <i class="fa-solid fa-copy mr-2"></i>
                        <span> {{ 'features.equipment.DUPLICATE' | translate}}</span>
                    </button>
                    <button type="button"
                        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 float-right my-3"
                        (click)="confirmRemoveEquipment(element.id, $event)">
                        <i class="fa-solid fa-trash-can mr-2"></i>
                        <span>{{ 'features.crew.DELETE_CREW_BUTTON' | translate }}</span>
                    </button>

                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row (click)="showEquipment(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [length]="totalCount" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]"
        aria-label="select page for equipments"></mat-paginator>
</div>