import { Component, OnInit, ElementRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@app/utils/auth-config';
import { environment } from '@env';

@Component({
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(document:click)': 'onClick($event)',
  },
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  animations: [
    trigger('AnimationTrigger2', [
      transition(':enter', [
        style({ transform: 'translateY(0.5rem)', opacity: 0 }),
        animate(
          '300ms ease-out',
          style({ transform: 'translateY(0)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ProfileMenuComponent implements OnInit {
  constructor(
    private authService: MsalService,
    private router: Router,
    private _eref: ElementRef
  ) {}

  isMenu = false;

  ngOnInit(): void {}

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }

  logout(): void {
    try {
      const myMsal = new PublicClientApplication(msalConfig);

      const logoutRequest = {
        account: myMsal.getAccountByHomeId(environment.clientId),
        postLogoutRedirectUri: environment.logoutRedirectUrl,
      };

      myMsal['browserStorage'].clear();

      this.authService.logoutRedirect(logoutRequest);
      localStorage.setItem('SDT_USER_PROFILE', '');
      localStorage.setItem('SDT_LOGIN_DATE', '');
    } catch (error) {}
  }

  onClick(event: Event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.isMenu = false;
    }
  }
}
