import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EquipmentService } from '@app/services/data/equipment.service';
import { Observable, takeUntil } from 'rxjs';
import { Reservation } from 'src/app/data';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';

@Component({
  selector: 'app-files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss'],
})
export class FilesUploadComponent
  extends SdtBaseComponent
  implements OnInit, OnChanges
{
  selectedFiles: any;
  progressInfos = [];
  message = '';
  files: any = [];
  fileInfos: any;
  documents: any[] = [];
  importedImages: any[] = [];

  @Output() filesToUpload: EventEmitter<any> = new EventEmitter<any>();

  @Input() reservation: Reservation | null = null;

  constructor(private equipmentService: EquipmentService) {
    super();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reservation) {
      this.documents = this.reservation?.documents;
    }
  }

  selectFiles(event: any) {}
  uploadFiles() {}

  addFile(file: any) {
    const mimeType = file.type;
    let imageUrl: any = '';

    if (mimeType.match(/image\/*/) !== null) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        imageUrl = reader.result;
        file.imageUrl = imageUrl;

        this.files.push(file);
        this.filesToUpload.emit(this.files);
      };
    } else {
      this.files.push(file);
      this.filesToUpload.emit(this.files);
    }
  }

  removeSavedFile(documentId: number) {
    this.equipmentService
      .deleteAppointmentDocument(
        this.site.code,
        this.reservation!.equipmentId!,
        this.reservation!.id!,
        documentId
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.documents = this.documents.filter((document) => {
          return document.id !== documentId;
        });
      });
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
    this.filesToUpload.emit(this.files);
  }

  isDocumentImage(name: string) {
    const imagesFormat = ['jpg', 'png', 'jpeg'];

    return imagesFormat.some((i) => name.includes(i));
  }

  dropHandler(ev: any) {
    ev.preventDefault();

    for (const file of ev.dataTransfer.files) {
      this.addFile(file);
    }
  }

  importFiles(ev: any) {
    ev.preventDefault();

    for (const file of ev.target.files) {
      this.addFile(file);
    }
  }

  dragEnterHandler(e: any) {
    e.preventDefault();
  }

  dragLeaveHandler(e: any) {}

  dragOverHandler(e: any) {}
}
