import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { Site } from '../../../data/Site.model';

@Injectable({
  providedIn: 'root',
})
export class SiteMenuService {
  private site: Site = {
    sitecode: '11',
    name: 'LaRonde',
    code: '11',
    description: 'Site LaRonde',
    id: 1,
    timeOffset: 'UTC+0',
  };

  sites!: Site[];

  constructor() {}

  getSite(): any {
    let data: any = localStorage.getItem('SDT_SITE');

    if (data) {
      data = JSON.parse(data);
    } else {
      data = this.site;
    }

    return data;
  }

  updateSite(site: Site): void {
    localStorage.setItem('SDT_SITE', JSON.stringify(site));
  }
}
