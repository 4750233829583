import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Equipment, Member } from 'src/app/data';
import { MatSidenav } from '@angular/material/sidenav';
import { CrewService } from '@app/services/data/crew.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Crew } from 'src/app/data';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import { Observable, takeUntil } from 'rxjs';
import { UserRoleService } from '@app/services/common/user-role.service';
import { ROLES } from '@app/constants/roles.constants';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from '../../../../../core/services/common/menu.service';

@Component({
  selector: 'app-crew-profile',
  templateUrl: './crew-profile.component.html',
  styleUrls: ['./crew-profile.component.scss'],
})
export class CrewProfileComponent extends SdtBaseComponent implements OnInit {
  @ViewChild('drawer') drawer!: MatSidenav;

  pageTitle = '';
  pageIconClass = 'fa-solid fa-users';
  selectedEquipment!: Equipment;
  selectedMember!: any;
  showFiller = false;
  isMemberSelected = false;
  crewId!: number;
  crew!: Crew;
  isAdmin = false;
  prevLink = '';
  updateCrew = 0;
  isSidenavCollapse = false;
  crewMembers!: Member[];

  constructor(
    private translate: TranslateService,
    private crewService: CrewService,
    private route: ActivatedRoute,
    private roleUserService: UserRoleService,
    private router: Router,
    private dialog: MatDialog,
    private menuService: MenuService
  ) {
    super();

    this.menuService.getModifyToggleSidenav().subscribe((toggle) => {
      this.isSidenavCollapse = toggle;
    });
  }

  async ngOnInit() {
    const crewId = this.route.snapshot.paramMap.get('id');

    if (crewId) {
      this.crewId = +crewId;
      this.loadCrew();
    }

    const roles = this.roleUserService.getRoles() || [];
    const siteManagerRole = ROLES.SITE_MANAGER.replace(
      '{siteId}',
      this.site.id
    );
    const sitePlanerRole = ROLES.SITE_PLANER.replace('{siteId}', this.site.id);
    const requiredRoles = [siteManagerRole, sitePlanerRole, ROLES.ADMIN];

    if (requiredRoles.some((r) => roles.includes(r))) {
      this.isAdmin = true;
    }

    this.prevLink = this.router.url.split('/').slice(0, -2).join('/');
  }

  loadCrew() {
    ++this.updateCrew;

    this.loadCrewMembers();

    this.crewService
      .getCrewById(this.crewId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((crew: Crew) => {
        this.crew = crew;
        this.pageTitle =
          this.crew.name +
          ' ' +
          this.translate.instant('features.crewProfile.TITLE_HOME');

        const userCrewManager = this.crew.managers?.find((manager) => {
          return manager.id === this.profile.id;
        });

        if (userCrewManager) {
          this.isAdmin = true;
        }
      });
  }

  selectEquipment(equipment: any) {
    this.isMemberSelected = false;
    this.selectedEquipment = equipment;
    this.drawer.toggle();
  }

  selectMember(member: any) {
    this.isMemberSelected = true;
    this.selectedMember = member;
    this.drawer.toggle();
  }

  closeDrawer() {
    this.drawer.toggle();
  }

  isMember(crew: Crew) {
    let isMember = false;

    const isUsermember: any = this.crewMembers?.filter(
      (member: any) => member.objectId === this.profile.objectId
    );

    if (isUsermember && isUsermember.length > 0) {
      isMember = true;
    }

    return isMember;
  }

  isNotMember(crew: Crew) {
    let isNotMember = true;

    const isUsermember: any = this.crewMembers?.filter(
      (member: any) => member.objectId === this.profile.objectId
    );

    let isUsermembershipPending: any = [];

    if (crew && crew.memberApprovals) {
      isUsermembershipPending = crew?.memberApprovals?.filter(
        (member: any) => member.requestorObjectId === this.profile.objectId
      );
    }

    if (
      (isUsermember && isUsermember.length > 0) ||
      isUsermembershipPending.length > 0
    ) {
      isNotMember = false;
    }

    return isNotMember;
  }

  loadCrewMembers() {
    this.crewService
      .getCrewMembers(this.crewId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((members: any) => {
        this.crewMembers = members.data;
      });
  }
}
