export const environment = {
  environment: 'QA',
  production: true,
  loginRedirectUrl: 'https://sddev.agnicoeagle.com/',
  logoutRedirectUrl: 'https://sddev.agnicoeagle.com/auth',
  backendBaseUrl: 'https://aemapdscheduledeliverybackend-dev.azurewebsites.net/api',
  clientId: '131f71b9-9d7b-4858-b779-2c34d485ca7b',
  authority:
    'https://login.microsoftonline.com/5f4e175c-44de-4676-ae70-23ec941f455d',
  AccessScope: 'api://f417b968-43d5-4343-94ca-638635f0b946/access_as_user',
  arcgis: {
    accessToken:
      'AAPK3d037583d5b54e8c811d76bc586b6f48z_MJZ4RJ2HVwt6CcpqBAhIUn8dTvJS4jMDWbMIeblqZh6MIzs5Iij-K_IBnDn-Dd',
  },
};
