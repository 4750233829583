export const RESERVATION_STATUS = [
  {
    id: 0,
    text: 'Cancelled',
    color: '#ff01b1',
  },
  {
    id: 1,
    text: 'Refused',
    color: '#ff0000',
  },
  {
    id: 2,
    text: 'No show',
    color: '#ff6d05',
  },
  {
    id: 3,
    text: 'Terminal',
    color: '#183bae',
  },
  {
    id: 4,
    text: 'Completed',
    color: '#28ae18',
  },
  {
    id: 5,
    text: 'Scheduled',
    color: '#c105ff',
  },
];
