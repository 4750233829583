import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Equipment, Reservation, UserProfile } from 'src/app/data/index';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import { Observable, take, takeUntil } from 'rxjs';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { ReservationService } from '@app/services/data/reservation.service';
import * as moment from 'moment';
import { UserRoleService } from '@app/services/common/user-role.service';
import { ROLES } from '@app/constants/roles.constants';
import { MenuService } from '@app/services/common/menu.service';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss'],
})
export class ReservationComponent extends SdtBaseComponent implements OnInit {
  pageIconClass = 'fa-solid fa-calendar-days';
  pageTitle = 'Reservations';
  reservations!: Reservation[];
  reservation!: Reservation;
  reservationId!: number;
  isAdminMenu = false;
  isAdmin = false;
  prevPageLink = '/home';
  startDate = moment().startOf('week').format('YYYY-MM-DD');
  endDate = moment().endOf('week').add(1, 'day').format('YYYY-MM-DD');
  weekDate = '';
  selectedDate = moment().format('YYYY-MM-DD');
  dateUpdated = '';
  userId: any = '';
  crewId: any = '';
  users!: any[];
  toggleSearchFilters = false;
  isShowAllHours = false;
  isSearchUniqueCode = false;
  equipId: any = '';
  categoryId: any = '';
  print: number = 0;
  isSidenavCollapse = false;

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private reservationService: ReservationService,
    private roleUserService: UserRoleService,
    private menuService: MenuService
  ) {
    super();
  }

  async ngOnInit() {
    if (this.router.url.includes('/admin')) {
      this.isAdminMenu = true;
      this.prevPageLink = '/admin';
    }

    const roles = this.roleUserService.getRoles() || [];
    const siteManagerRole = ROLES.SITE_MANAGER.replace(
      '{siteId}',
      this.site.id
    );
    const sitePlanerRole = ROLES.SITE_PLANER.replace('{siteId}', this.site.id);
    const requiredRoles = [siteManagerRole, sitePlanerRole, ROLES.ADMIN];

    if (requiredRoles.some((r) => roles.includes(r))) {
      this.isAdmin = true;
    }

    this.userProfileService
      .getMyUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: UserProfile) => {
        this.userId = userProfile.id;
        this.profile = userProfile;
        this.getReservations();
      });

    this.menuService.getModifyToggleSidenav().subscribe((toggle) => {
      this.isSidenavCollapse = toggle;
    });
  }

  searchByUniqueCode(isUniqueCode: boolean) {
    this.isSearchUniqueCode = isUniqueCode;
  }

  getReservations() {
    this.reservationService
      .searchReservation(
        this.site.code,
        '',
        this.categoryId,
        this.crewId,
        this.startDate,
        this.endDate,
        this.equipId,
        '',
        ''
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        console.log('result', result);
        this.reservations = result.data;

        this.getDistincsUsers(this.reservations);
      });
  }

  printCalendar() {
    ++this.print;
  }

  selectedReservation(reservation: Reservation) {
    this.reservation = reservation;
  }

  selectedReservationId(id: number) {
    this.reservationId = id;
  }

  deletedReservationId(id: number) {
    this.reservations = this.reservations.filter(
      (reservation) => reservation.id !== id
    );
  }

  updatedDate(date: any) {
    this.dateUpdated = date;
  }

  showAllHours(isShowAllHours: any) {
    this.isShowAllHours = isShowAllHours;
  }

  dateSelected(date: any) {
    this.selectedDate = date;
  }

  reservationSearch(reservations: Reservation[]) {
    this.reservations = reservations;
  }

  selectedWeek(weekDate: any) {
    this.weekDate = weekDate;
    this.startDate = weekDate.startDate;
    this.endDate = weekDate.endDate;

    if (this.isSearchUniqueCode === false) {
      this.getReservations();
    }
  }

  getDistincsUsers(reservations: Reservation[]) {
    this.users = [
      ...new Map(
        reservations.map((item) => [
          item['userId'],
          {
            userId: item.userId,
            userName: item.userName,
          },
        ])
      ).values(),
    ];

    const myUser = this.users.find((user) => {
      return user.userId === this.profile.id;
    });

    if (!myUser) {
      this.users.unshift({
        userId: this.profile.id,
        userName: this.profile.name,
      });
    }
  }

  showAdd(event: any) {
    event.stopImmediatePropagation();

    this.router.navigate(['reservation/new']);
  }

  toggleFilters(event: any) {
    event.stopImmediatePropagation();

    this.toggleSearchFilters = !this.toggleSearchFilters;
  }
}
