<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="[prevPageLink]" [showChevron]=true>
    <div content class="sm:hidden inline-flex">
        <button (click)="toggleFilters($event)" type="button"
            class="inline-flex absolute right-[74px] top-[12px] items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gray-800">
            <i class="fa-solid fa-sliders">
            </i>
        </button>
        <button (click)="showAdd($event)" type="button"
            class="inline-flex absolute right-[14px] top-[12px] items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-800">
            <i class="fa-solid fa-plus">
            </i>
        </button>
    </div>
    <div content class="hidden sm:inline-flex">
        <button (click)="showAdd($event)" type="button"
            [class]="isSidenavCollapse ? 'lg:right-[80px]' : 'lg:right-[280px]'"
            class="inline-flex absolute right-2 sm:right-[80px] top-[8px] items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-800">
            <i class="fa-solid fa-plus sm:mr-2">
            </i>
            <span class="inline-block">
                {{ 'features.reservation.ADD_RESERVATION' | translate}}
            </span>
        </button>
    </div>
</app-page-title>
<div class="flex-1 relative sm:z-0 flex sm:overflow-hidden">
    <main class="flex-1 relative sm:z-0 overflow-y-auto focus:outline-none md:order-last"
        style="background-color: #f8fafc;">

        <article>

            <app-reservation-search [dateUpdated]="dateUpdated" [toggleSearchFilters]="toggleSearchFilters"
                [users]="users" [weekDate]="weekDate" (dateSelected)="dateSelected($event)"
                (reservationChange)="reservationSearch($event)" (showAllHours)="showAllHours($event)"
                (printCalendar)="printCalendar()" (isSearchByUniqueCode)="searchByUniqueCode($event)">
            </app-reservation-search>

            <app-reservation-timeline [printCalendar]="print" [isShowAllHours]="isShowAllHours"
                (updatedDate)="updatedDate($event)" (selectedWeek)="selectedWeek($event)"
                (getReservations)="getReservations()" [reservations]="reservations" [selectedDate]="selectedDate"
                [isSearchUniqueCode]="isSearchUniqueCode"></app-reservation-timeline>

        </article>
    </main>
</div>