<app-page-title [iconClass]="pageIconClass" title="FAQ"
    [routerLink]="['/home']" [showChevron]=true>
</app-page-title>
<div class="flex-1 relative z-0 flex overflow-hidden" style="min-height: calc(100vh);">
    <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none md:order-last pb-[60px] "
        style="background-color: #f8fafc;">

        <article class="md:mx-20 mb-20">
            <div class=" mt-20 mb-20 ">
                <div class="text-center">
                    <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">{{ 'features.faq.TITLE_HOME'| translate }}</h1>
                    <a [href]="mailAPD" class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center aem-button text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300">
                        {{ 'features.faq.OPEN_TICKET'| translate }}
                        <svg class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </a>
                </div>
            </div>
            <!-- FAQ Update -->
            <app-faq-update></app-faq-update>
            <div class="mx-20 my-10 "><hr></div>

           
        </article>

    </main>
</div>