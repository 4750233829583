import { Component, OnDestroy, Injector } from '@angular/core';
import { DialogService } from '@app/services/common/dialog.service';
import { Subject } from 'rxjs/internal/Subject';
import { filter } from 'rxjs';
import { SiteMenuService } from '@app/services/common/site-menu.service';
import { Site } from 'src/app/data';
import { UserProfile } from 'src/app/data';
import { MenuService } from '../core/services/common/menu.service';

/**
 * Base component with common features
 *
 */
@Component({
  template: '',
})
export abstract class SdtBaseComponent implements OnDestroy {
  siteMenuService: SiteMenuService;
  // menuService: MenuService;
  public site!: any;
  public profile!: any;
  public sites!: Site[];
  arr: any[] = [];

  injector = Injector.create({
    providers: [
      { provide: SiteMenuService, deps: [] },
      // { provide: MenuService, deps: [] },
    ],
  });

  constructor() {
    this.siteMenuService = this.injector.get(SiteMenuService);
    // this.menuService = this.injector.get(MenuService);
    this.site = this.siteMenuService.getSite();
    this.getUserProfileFromLocalStorage();

    // this.menuService.getModifyDefaultSite().subscribe(() => {
    //   const localStorageSite = localStorage.getItem('SDT_SITE');
    //   const site = JSON.parse(localStorageSite!);
    //   this.site = site;
    // });
  }

  protected destroy$: Subject<void> = new Subject();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  getUserProfileFromLocalStorage() {
    const userProfile = localStorage.getItem('SDT_USER_PROFILE');
    try {
      if (userProfile) {
        this.profile = JSON.parse(userProfile);
      }
    } catch (error) {}
  }
}
