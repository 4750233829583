<div *ngIf="equipments"
    class="px-4 py-2.5 sm:px-6 bg-yellow-default flex justify-between items-center flex-wrap sm:flex-nowrap">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
        <i class="fa-solid fa-truck mr-2"></i>
        {{ 'features.equipment.EQUIPMENT' | translate }} ({{equipments.length}} {{ 'features.equipment.ITEMS' |
        translate}})
    </h3>
    <button type="button" (click)="addEquipment()" *ngIf="editable"
        class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-lg text-sm font-medium rounded-md text-white bg-black-default hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-default">
        <i class="fa-solid fa-plus"></i>
    </button>
</div>
<div *ngIf="equipments" class="border-t border-gray-200 bg-gray-50 px-4 py-4 sm:p-0">
    <div class="relative bg-gray-50">
        <div class="relative pb-4">

            <p-carousel [value]="equipments" [numVisible]="6" [numScroll]="6" [circular]="true"
                [responsiveOptions]="responsiveOptions" [showIndicators]="false">
                <ng-template let-equipment pTemplate="item">

                    <div (click)="selectEquipment(equipment)"
                        class="cursor-pointer rounded-lg shadow overflow-hidden relative" style="margin: 15px;">
                        <div class="h-20 flex justify-center bg-white">
                            <img class="h-20" [src]="equipment.pictureUri || './assets/img/DockIcon.png'" alt="">
                        </div>
                        <div class="flex-1 bg-gray-100 px-6 py-2 flex flex-col justify-between">
                            <div class="flex-1">
                                <div class="absolute flex-shrink-0 flex items-center justify-center">
                                    <span
                                        [ngClass]="{'bg-green-500': equipment.isActive, 'bg-red-500': !equipment.isActive}"
                                        class="mt-1 h-3 w-3 rounded-full bg-green-500" aria-hidden="true">
                                    </span>
                                </div>
                                <p class="ml-5 text-sm font-semibold text-blue-darker">
                                    {{ equipment.name }}
                                </p>
                            </div>
                        </div>
                    </div>

                </ng-template>
            </p-carousel>

            <div class="mx-8">
                <a href="javascript:void(0)" [routerLink]="['equipments/detail']"
                    class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                    {{ 'features.equipment.VIEW_ALL' | translate }} </a>
            </div>
        </div>
    </div>
</div>