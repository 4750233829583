import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Member } from 'src/app/data';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { Observable, takeUntil } from 'rxjs';
import { UserProfile } from 'src/app/data';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import { Reservation } from 'src/app/data/index';

@Component({
  selector: 'app-user-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss'],
})
export class ProfileDetailComponent
  extends SdtBaseComponent
  implements OnInit, OnChanges
{
  userProfile!: UserProfile;
  reservations!: Reservation[];
  tab = 'profile';

  @Input() member!: Member;

  constructor(
    private router: Router,
    private userProfileService: UserProfileService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.member && this.member.id) {
      this.loadUserProfile();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.member && this.member.id) {
      this.loadUserProfile();
      this.getReservations();
      this.tab = 'profile';
    }
  }

  getReservations() {
    this.userProfileService
      .getReservations(this.member.id, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.reservations = result.data;
      });
  }

  loadUserProfile() {
    this.userProfileService
      .getUserProfile(this.member.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: any) => {
        this.userProfile = userProfile;
      });
  }

  selectTab(tab: string): void {
    this.tab = tab;
  }

  showEdit() {
    this.router.navigate(['/profile/edit']);
  }
}
