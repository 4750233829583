import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from '@app/services/data/site.service';
import { merge, startWith, Subject, takeUntil, switchMap, map } from 'rxjs';
import { Site, PaginatedItems, Timezone as TZ } from 'src/app/data';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { SdtBaseComponent } from '../../SdtBaseComponent';
import { Timezone } from 'src/app/core/utils/timezone';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
})
export class SiteComponent extends SdtBaseComponent implements AfterViewInit {
  displayedColumns: string[] = ['siteName', 'code', 'timeZone', 'description'];
  dataSource: Site[] = [];

  @ViewChild(MatTable)
  table!: MatTable<Site>;

  pageTitle = this.translate.instant('features.site.TITLE_HOME');

  pageIconClass = 'fa-solid fa-location-dot';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  totalCount: number = 0;
  isLoadingResults = true;
  timezones: TZ[] = [];

  constructor(
    private siteService: SiteService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.loadTimezones();
    this.loadSites();
  }

  /**
   * get timeZone
   */
  getTimezone(timeOffset: string) {
    return Timezone.getTimezone(this.timezones, timeOffset);
  }

  /**
   * Load timezones data.
   */
  loadTimezones() {
    this.siteService
      .getTimezones()
      .pipe(takeUntil(this.destroy$))
      .subscribe((timezones: any) => {
        this.timezones = timezones;
        console.log('timezones', timezones);
      });
  }

  /**
   * Load sites with paginator data.
   */
  loadSites() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.siteService.getSites(this.paginator.pageIndex);
        }),
        map((resp: PaginatedItems<Site>) => {
          this.isLoadingResults = false;
          this.totalCount = resp.count;
          return resp.data;
        })
      )
      .subscribe(
        (data) => {
          this.dataSource = data;
        },
        (err) => {
          this.isLoadingResults = false;
        }
      );
  }

  /**
   * Redirect to add site compenent
   */
  addSite() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  showSite(site: Site) {
    if (site) {
      this.router.navigate(['modify', site.id], { relativeTo: this.route });
    }
  }
}
