<div class="reservation-detail">
    <div class="mx-auto">

        <div *ngIf="!isFromMemberList"
            class="py-2 px-4 flex-col flex items-center justify-between mt-[-48px] sm:mt-[-20px]"
            style="background: #f7f7f7;">
            <div class="flex self-end space-x-3 md:mt-0 md:ml-4">
                <div class="md:mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <button (click)="confirmRemoveReservation()" type="button"
                        [ngClass]="{'invisible': !isEventSelected || !canEditReservation}"
                        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600">
                        <i class="fa-solid fa-trash-can sm:mr-2">
                        </i>
                        <span class="hidden md:inline-block">
                            {{ 'features.reservation.DELETE_RESERVATION' | translate }}
                        </span>
                    </button>
                    <button (click)="showEdit()" type="button"
                        [ngClass]="{'invisible': !isEventSelected || !canEditReservation}"
                        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-darker">
                        <i class="fa-solid fa-pen sm:mr-2">
                        </i>
                        <span class="hidden md:inline-block">
                            {{ 'features.reservation.EDIT_RESERVATION' | translate }}
                        </span>
                    </button>

                    <button (click)="showAdd()" type="button"
                        class="hidden sm:inline-flex  items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-800">
                        <i class="fa-solid fa-plus sm:mr-2">
                        </i>
                        <span class="hidden md:inline-block">
                            {{ 'features.reservation.ADD_RESERVATION' | translate}}
                        </span>

                    </button>
                </div>
            </div>
        </div>

        <mbsc-eventcalendar [invalid]="invalidDate" [locale]="locale" #eventCalendar class="mb-10" [data]="myEvents"
            [options]="eventSettings">
        </mbsc-eventcalendar>

        <mbsc-popup class="md-tooltip" #popup [anchor]="anchor" [options]="popupOptions">
            <div *ngIf="hoverEvent" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
                <div class="md-tooltip-header" [ngStyle]="{background: hoverEvent?.color!}">
                    <span class="md-tooltip-name-age">{{hoverEvent?.userName!}}</span>
                    <span class="md-tooltip-time">{{time}}</span>
                </div>
                <div class="md-tooltip-info">
                    <div class="md-tooltip-title">
                        Equipment: <span class="md-tooltip-status md-tooltip-text">{{hoverEvent?.equipmentName}}</span>
                        <div>
                            <span class="md-tooltip-title pr-1">Comment:</span>
                            <span class="md-tooltip-text">
                                {{hoverEvent?.description || 'N/A'}}
                            </span>
                        </div>
                        <!-- 
                        <mbsc-button [color]="buttonType" variant="outline" class="md-tooltip-status-button"
                            (click)="setStatus()">{{buttonText}}</mbsc-button> -->
                    </div>

                    <div class="md-tooltip-title">
                        <div *ngIf="hoverEvent?.recurring">
                            <div class="rounded bg-gray-300 w-20 px-1">Recurring:</div>
                            <div *ngIf="getCustomRule(hoverEvent?.recurring).FREQ" class="">
                                <span class="md-tooltip-title pr-1">Frequency: </span>
                                <span class="md-tooltip-text">{{getCustomRule(hoverEvent?.recurring).FREQ}}</span>
                            </div>
                            <div *ngIf="getCustomRule(hoverEvent?.recurring).INTERVAL" class="">
                                <span class="md-tooltip-title pr-1">Interval:</span>
                                <span class="md-tooltip-text">{{getCustomRule(hoverEvent?.recurring).INTERVAL}}</span>
                            </div>
                            <div *ngIf="getCustomRule(hoverEvent?.recurring).BYDAY" class="">
                                <span class="md-tooltip-title pr-1">Day:</span>
                                <span class="md-tooltip-text">{{getCustomRule(hoverEvent?.recurring).BYDAY}}</span>
                            </div>
                            <div *ngIf="getCustomRule(hoverEvent?.recurring).UNTIL" class="">
                                <span class="md-tooltip-title pr-1">Until:</span>
                                <span class="md-tooltip-text">{{getCustomRule(hoverEvent?.recurring).UNTIL}}</span>
                            </div>
                        </div>
                    </div>

                    <button type="button" (click)="confirmStopCurrentReservation(hoverEvent.equipmentId, hoverEvent.id)"
                        *ngIf="canStopCurrentReservationHover(hoverEvent)"
                        class="hidden sm:inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-800 ">
                        <i class="fa-solid fa-circle-stop sm:mr-2">
                        </i>
                        <span class="hidden md:inline-block">
                            {{ 'features.reservation.END_CURRENT_RESERVATION' | translate}}
                        </span>

                    </button>

                    <button type="button"
                        (click)="confirmCancelRecurringReservationInstance(hoverEvent.equipmentId, hoverEvent.id, hoverEvent.start)"
                        *ngIf="canCancelInstanceRecurrenceReservationHover(hoverEvent)"
                        class="hidden sm:inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-800 ">
                        <i class="fa-solid fa-circle-stop sm:mr-2">
                        </i>
                        <span class="hidden md:inline-block">
                            {{ 'features.reservation.CANCEL_INSTANCE_RECURRING_RESERVATION' | translate}}
                        </span>
                    </button>

                    <button type="button" (click)="confirmExtendReservation(hoverEvent)"
                        *ngIf="canExtendReservationHover(hoverEvent)"
                        class="hidden sm:inline-flex items-center px-4 py-2 mt-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 ">
                        <i class="fa-solid fa-clock sm:mr-2">
                        </i>
                        <span class="hidden md:inline-block">
                            {{ 'features.reservation.EXTEND_RESERVATION' | translate}} (15mn)
                        </span>
                    </button>
                </div>
            </div>
        </mbsc-popup>
    </div>
</div>

<div *ngIf="!isFromMemberList && isEventSelected"
    class="shadow-xl sm:hidden fixed bottom-0 w-full px-[10px] py-[15px] !pt-[40px]"
    style="background-color: rgba(0, 0, 0, 0.8); z-index: 100; background-color: rgba(0, 0, 0, 0.8);">
    <div class="md:mt-6 flex flex-wrap gap-y-2 space-x-3 md:mt-0 md:ml-4">
        <button (click)="confirmRemoveReservation()" type="button" [ngClass]="{'invisible': !isEventSelected}"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600">
            <i class="fa-solid fa-trash-can mr-2">
            </i>
            <span>
                {{ 'features.reservation.DELETE' | translate }}
            </span>
        </button>
        <button type="button"
            (click)="confirmCancelRecurringReservationInstance(selectedReservation.equipmentId, selectedReservation.id, selectedReservation.start)"
            *ngIf="canCancelInstanceRecurrenceReservationHover(selectedReservation)"
            [ngClass]="{'invisible': !isEventSelected}"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-800">
            <i class="fa-solid fa-circle-stop mr-2">
            </i>
            <span class="">
                {{ 'features.reservation.CANCEL' | translate}}
            </span>
        </button>
        <button type="button" (click)="confirmExtendReservation(selectedReservation)"
            *ngIf="canExtendReservationHover(selectedReservation)" [ngClass]="{'invisible': !isEventSelected}"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800">
            <i class="fa-solid fa-clock mr-2">
            </i>
            <span class="">
                {{ 'features.reservation.EXTEND' | translate}} (15mn)
            </span>
        </button>
        <button (click)="showEdit()" type="button" [ngClass]="{'invisible': !isEventSelected}"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-darker">
            <i class="fa-solid fa-pen mr-2">
            </i>
            <span>
                {{ 'features.reservation.EDIT' | translate }}
            </span>
        </button>
        <button (click)="confirmStopCurrentReservation(selectedReservation.equipmentId, selectedReservation.id)"
            *ngIf="canStopCurrentReservation()" type="button" [ngClass]="{'invisible': !isEventSelected}"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-800">
            <i class="fa-solid fa-circle-stop mr-2">
            </i>
            <span>
                {{ 'features.reservation.END_CURRENT' | translate }}
            </span>
        </button>

        <i (click)="closeActionbar()"
            class="fa-solid fa-circle-xmark absolute right-[11px] top-[5px] text-white text-[29px] cursor-pointer"
            style="z-index: 100;"></i>
    </div>
</div>