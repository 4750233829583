import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../core/services/common/menu.service';
import { environment } from '@env';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@app/utils/auth-config';
import { MsalService } from '@azure/msal-angular';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { takeUntil } from 'rxjs';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import { UserProfile } from 'src/app/data';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends SdtBaseComponent implements OnInit {
  mailAPD: string = `mailto:apd.services@agnicoeagle.com?subject=SCHEDULE DELIVERY TOOL: Help / Feedback`;
  isSidebarOpen = false;
  env = environment;
  userProfile!: UserProfile;

  constructor(
    private menuService: MenuService,
    private authService: MsalService,
    private userProfileService: UserProfileService
  ) {
    super();

    this.menuService
      .getModifyToggle()
      .subscribe((toggle) => (this.isSidebarOpen = toggle));
  }

  ngOnInit(): void {
    this.loadMyProfile();
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.menuService.toggleMenu(this.isSidebarOpen);
  }

  logout(): void {
    try {
      const myMsal = new PublicClientApplication(msalConfig);

      const logoutRequest = {
        account: myMsal.getAccountByHomeId(environment.clientId),
        postLogoutRedirectUri: environment.logoutRedirectUrl,
      };

      myMsal['browserStorage'].clear();

      this.authService.logoutRedirect(logoutRequest);
      localStorage.setItem('SDT_USER_PROFILE', '');
      localStorage.setItem('SDT_LOGIN_DATE', '');
    } catch (error) {}
  }

  loadMyProfile() {
    this.userProfileService
      .getMyUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: any) => {
        this.userProfile = userProfile;
        localStorage.setItem('SDT_USER_PROFILE', JSON.stringify(userProfile));
      });
  }
}
