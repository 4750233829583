<div [ngClass]="{'h-[500px]': isSHowFilters, 'h-0': !isSHowFilters}"
    class="sm:top-0 left-0 bg-gray-200 fixed sm:relative sm:h-auto px-4 sm:px-6 lg:px-8 border border-gray-300 border-b-1 border-t-0 border-l-0 border-r-0 transition-height delay-150 duration-300 overflow-hidden w-full timeline-search"
    style="box-shadow: inset 0px 1px 3px 1px rgb(0 0 0 / 22%); background-color: #e2eaf2 !important; z-index: 10000;">
    <ul role="list" class="mt-[-14px] mb-[19px] h-[50px] grid grid-cols-1 gap-4 sm:gap-4 sm:grid-cols-4 xl:grid-cols-4">

        <li class="relative col-span-1 flex rounded-md w-[200px]">
            <div>
                <label class="text-sm text-gray-700 inline-block mr-2 mt-[31.5px]">{{ 'features.reservation.SEARCH' |
                    translate}}</label>
            </div>
            <mat-form-field [style.width.%]="100">
                <input [(ngModel)]="searchText" variant="outlined" matInput placeholder="by unique code"
                    (keyup)=" term.next($any($event).target.value)">
                <i *ngIf="searchText" class="cursor-pointer fa-solid fa-xmark text-gray-500 absolute right-5 top-2"
                    (click)="clearSearchText($event)"></i>
            </mat-form-field>
        </li>

        <!--        <li class="relative col-span-4 rounded-md">
            <div class="">
                <mat-checkbox (change)="toggleShowAllHours()" class="mr-2 relative top-[-1px]"
                    [(ngModel)]="isShowAllHours">Show all hours</mat-checkbox>
                <span class="ml-1 mr-2">|</span>
                <label class="text-sm text-gray-700 inline-block mr-2">Select a date: </label>
                <mat-form-field [style.width.px]="90">
                    <input mbsc-datepicker [mbscOptions]="datepickerOptionsStartDate" variant="outlined"
                        [(ngModel)]="selectedDate" matInput>
                </mat-form-field>
                 <button (click)="print()" type="button"
                    class="inline-flex ml-3 mt-[0px] items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-default hover:bg-blue-darker">
                    <i class="fa-solid fa-print">
                    </i>
                </button> 
</div>
</li>
-->
    </ul>
    <div class=" shadow absolute text-center left-0 right-0 bottom-0 sm:hidden m-auto cursor-pointer w-[200px] py-1 px-4 rounded-t-lg"
        style="background-color: #c8c8c8;" (click)="toggleFilters()">
        <i class="fa-solid fa-sliders mr-2"></i>
        <span>{{ 'features.equipment.HIDE_FILTERS' | translate}}</span>
    </div>
</div>