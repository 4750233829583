import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import {
  Category,
  Crew,
  PaginatedItems,
  Equipment,
  Reservation,
} from 'src/app/data';
import { EquipmentService } from '@app/services/data/equipment.service';
import { CategoryService } from '@app/services/data/category.service';
import { CrewService } from '@app/services/data/crew.service';
import {
  map,
  Observable,
  startWith,
  Subject,
  takeUntil,
  debounceTime,
} from 'rxjs';

@Component({
  selector: 'app-equipment-search',
  templateUrl: './equipment-search.component.html',
  styleUrls: ['./equipment-search.component.scss'],
})
export class EquipmentSearchComponent
  extends SdtBaseComponent
  implements OnInit, OnChanges
{
  categories: Category[] | undefined;
  equipments: Equipment[] | undefined;
  crews: Crew[] | undefined;
  searchText: string = '';
  term = new Subject<string>();
  isInactive = false;
  crewId: string = '';
  categoryId: string = '';
  isSHowFilters = false;

  @Input() toggleSearchFilters!: any;
  @Input() selectedcrewId!: string;
  @Output() equipmentsChange: EventEmitter<Equipment[]> = new EventEmitter<
    Equipment[]
  >();

  constructor(
    private crewService: CrewService,
    private catService: CategoryService,
    private equipmentService: EquipmentService
  ) {
    super();

    this.term.pipe(debounceTime(1000)).subscribe((val) => {
      this.searchEquipments();
    });
  }

  ngOnInit(): void {
    this.loadCategories();
    this.loadCrews();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.toggleSearchFilters &&
      !changes.toggleSearchFilters.firstChange
    ) {
      this.toggleFilters();
    }
  }

  loadCategories() {
    this.catService
      .getCategories(this.site.code, 0, 0)
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Category>) => {
          return resp.data;
        })
      )
      .subscribe((categories) => {
        this.categories = categories;
      });
  }

  loadCrews() {
    this.crewService
      .getPaginatedCrews(this.site.code, 0, 0)
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Crew>) => {
          return resp.data;
        })
      )
      .subscribe((crews) => {
        this.crews = crews;
      });
  }

  searchEquipments() {
    this.equipmentService
      .searchEquipment(
        this.site.code,
        this.searchText,
        this.categoryId,
        this.crewId,
        this.isInactive ? false : true
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        this.equipments = result.data;
        this.equipmentsChange.emit(this.equipments);
      });
  }

  clearSearchText(e: any) {
    e.stopImmediatePropagation();
    this.searchText = '';

    this.searchEquipments();
  }

  clearCategory(e: any) {
    e.stopImmediatePropagation();
    this.categoryId = '';

    this.searchEquipments();
  }

  clearCrew(e: any) {
    e.stopImmediatePropagation();
    this.crewId = '';

    this.searchEquipments();
  }

  toggleFilters() {
    this.isSHowFilters = !this.isSHowFilters;
  }
}
