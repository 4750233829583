import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import { CrewService } from '@app/services/data/crew.service';
import { Observable, takeUntil } from 'rxjs';
import { Member, PaginatedItems } from 'src/app/data';

@Component({
  selector: 'app-members-carousel',
  templateUrl: './members-carousel.component.html',
  styleUrls: ['./members-carousel.component.scss'],
})
export class MembersCarouselComponent
  extends SdtBaseComponent
  implements OnInit, OnChanges
{
  @Input() udpateCrew!: number;
  @Input() editable: boolean = true;
  @Output() selectedMember: EventEmitter<any> = new EventEmitter<any>();

  crewId!: number;
  crewMembers!: Member[];
  responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private crewService: CrewService
  ) {
    super();
  }

  ngOnInit(): void {
    const crewId = this.route.snapshot.paramMap.get('id');

    if (crewId) {
      this.crewId = +crewId;
      this.loadCrewMembers();
    }
  }

  addMember() {
    this.router.navigate(['member/add'], {
      relativeTo: this.route,
      state: { parent: `/admin/crew/profile/${this.crewId}` },
    });
  }

  loadCrewMembers() {
    this.crewService
      .getCrewMembers(this.crewId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((members: any) => {
        this.crewMembers = members.data;
      });
  }

  selectMember(member: any) {
    this.selectedMember.emit(member);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.udpateCrew && changes.udpateCrew.currentValue > 1) {
      this.loadCrewMembers();
    }
  }
}
