import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { UserRoleService } from 'src/app/core/services/common/user-role.service';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import { Observable, takeUntil } from 'rxjs';
import { UserProfile } from './data';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends SdtBaseComponent implements OnInit {
  title = 'sdtapp';
  userProfile!: UserProfile;

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userRoleService: UserRoleService,
    private userProfileService: UserProfileService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadMyProfile();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        )
      )
      .subscribe((result: EventMessage) => {
        localStorage.setItem(
          'SDT_LOGIN_DATE',
          moment().format('YYYY-MM-DD HH:mm:ss')
        );

        this.userRoleService.fetchAccessToken();
      });
  }

  loadMyProfile() {
    this.userProfileService
      .getMyUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: UserProfile) => {
        this.userProfile = userProfile;
      });
  }
}
