import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  Category,
  Crew,
  PaginatedItems,
  Equipment,
  Reservation,
  UserProfile,
} from 'src/app/data';
import { EquipmentService } from '@app/services/data/equipment.service';
import { CategoryService } from '@app/services/data/category.service';
import { ReservationService } from '@app/services/data/reservation.service';
import { CrewService } from '@app/services/data/crew.service';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import { UserProfileService } from '@app/services/data/user-profile.service';
import {
  map,
  Observable,
  startWith,
  Subject,
  takeUntil,
  debounceTime,
} from 'rxjs';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { UserRoleService } from '@app/services/common/user-role.service';
import { ROLES } from '@app/constants/roles.constants';
import { MbscDatepickerOptions } from '@mobiscroll/angular';

@Component({
  selector: 'app-reservation-search',
  templateUrl: './reservation-search.component.html',
  styleUrls: ['./reservation-search.component.scss'],
})
export class ReservationSearchComponent
  extends SdtBaseComponent
  implements OnInit, OnChanges
{
  @Input() toggleSearchFilters!: any;
  @Input() weekDate!: any;
  @Input() dateUpdated!: string;
  @Input() users!: any;

  @Output() reservationChange: EventEmitter<Reservation[]> = new EventEmitter<
    Reservation[]
  >();

  @Output() printCalendar: EventEmitter<null> = new EventEmitter<null>();
  @Output() showAllHours: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() dateSelected: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();

  @Output() isSearchByUniqueCode: EventEmitter<boolean> =
    new EventEmitter<boolean>(false);

  isShowAllHours: boolean = false;
  equipments: Equipment[] | undefined;
  categories: Category[] | undefined;
  reservations!: Reservation[];
  crews: Crew[] | undefined;
  equipmentId: any = '';
  crewId: string = '';
  categoryId: string = '';
  startDate = moment().startOf('week').format('YYYY-MM-DD');
  selectedDate = moment().format('YYYY-MM-DD');
  endDate = moment().endOf('week').add(1, 'day').format('YYYY-MM-DD');
  searchText: string = '';
  term = new Subject<string>();
  userId: any = '';
  isAdmin = false;
  isSHowFilters = false;

  datepickerOptionsStartDate: MbscDatepickerOptions = {
    moment: moment,
    returnFormat: 'moment',
    select: 'date',
    controls: ['calendar'],
    onClose: (event, inst) => {
      console.log('event', event);
      console.log('event.valueText', event.valueText);
      console.log('inst', inst);

      const date = moment(event.valueText, 'MM/DD/YYYY');
      this.selectedDate = date.format('YYYY-MM-DD');

      console.log('this.selectedDate', this.selectedDate);

      this.searchDate();
    },
  };

  constructor(
    private router: Router,
    private crewService: CrewService,
    private catService: CategoryService,
    private equipmentService: EquipmentService,
    private reservationService: ReservationService,
    private roleUserService: UserRoleService,
    private userProfileService: UserProfileService
  ) {
    super();

    this.term.pipe(debounceTime(1000)).subscribe((val) => {
      if (val === '') {
        this.isSearchByUniqueCode.emit(false);
      } else {
        this.isSearchByUniqueCode.emit(true);
      }

      this.searchReservations();
    });
  }

  async ngOnInit() {
    const roles = this.roleUserService.getRoles() || [];
    const siteManagerRole = ROLES.SITE_MANAGER.replace(
      '{siteId}',
      this.site.id
    );
    const sitePlanerRole = ROLES.SITE_PLANER.replace('{siteId}', this.site.id);
    const requiredRoles = [siteManagerRole, sitePlanerRole, ROLES.ADMIN];

    if (requiredRoles.some((r) => roles.includes(r))) {
      this.isAdmin = true;
    }

    this.loadEquipments();
    this.loadCategories();
    this.loadCrews();

    this.userProfileService
      .getMyUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: UserProfile) => {
        this.userId = userProfile.id;
        this.profile = userProfile;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.weekDate &&
      changes.weekDate.currentValue.startDate &&
      changes.weekDate.currentValue.endDate
    ) {
      this.startDate = changes.weekDate.currentValue.startDate;
      this.endDate = changes.weekDate.currentValue.endDate;
    }

    if (
      changes.toggleSearchFilters &&
      !changes.toggleSearchFilters.firstChange
    ) {
      this.toggleFilters();
    }

    if (changes.dateUpdated && !changes.dateUpdated.firstChange) {
      this.selectedDate = changes.dateUpdated.currentValue;
    }
  }

  toggleShowAllHours() {
    this.showAllHours.emit(this.isShowAllHours);
  }

  print() {
    this.printCalendar.emit();
  }

  loadCategories() {
    this.catService
      .getCategories(this.site.code, 0, 0)
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Category>) => {
          return resp.data;
        })
      )
      .subscribe((categories) => {
        this.categories = categories;
      });
  }

  loadEquipments() {
    this.equipmentService
      .getEquipments(this.site.code, 0, 0)
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Equipment>) => {
          return resp.data;
        })
      )
      .subscribe((equipments) => {
        this.equipments = equipments;
      });
  }

  loadCrews() {
    this.crewService
      .getPaginatedCrews(this.site.code, 0, 0)
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Crew>) => {
          return resp.data;
        })
      )
      .subscribe((crews) => {
        this.crews = crews;
      });
  }

  searchDate() {
    this.dateSelected.emit(this.selectedDate);

    this.startDate = moment(this.selectedDate, 'YYYY-MM-DD')
      .weekday(0)
      .format('YYYY-MM-DD');

    this.endDate = moment(this.selectedDate, 'YYYY-MM-DD')
      .weekday(6)
      .format('YYYY-MM-DD');

    this.searchReservations();
  }

  searchReservations() {
    this.reservationService
      .searchReservation(
        this.site.code,
        '',
        this.categoryId,
        this.crewId,
        this.searchText ? '' : this.startDate,
        this.searchText ? '' : this.endDate,
        this.equipmentId,
        '',
        this.searchText
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        this.reservations = result.data;
        this.reservationChange.emit(this.reservations);
      });
  }

  clearSearchText(e: any) {
    e.stopImmediatePropagation();
    this.searchText = '';

    this.isSearchByUniqueCode.emit(false);
    this.searchReservations();
  }

  toggleFilters() {
    this.isSHowFilters = !this.isSHowFilters;
  }
}
