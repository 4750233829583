import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from '@app/services/common/dialog.service';
import { CrewService } from '@app/services/data/crew.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, takeUntil } from 'rxjs';
import { Crew } from 'src/app/data';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-crew',
  templateUrl: './add-crew.component.html',
  styleUrls: ['./add-crew.component.scss'],
})
export class AddCrewComponent extends SdtBaseComponent implements OnInit {
  crewId!: number;
  isFormSaved: boolean = false;
  crewForm!: FormGroup;
  pageIconClass = 'fa-solid fa-circle-plus';
  pageTitle = this.translate.instant('features.addCrew.TITLE_HOME');
  crewManager = this.translate.instant('features.addCrew.CREW_MANAGER');
  prevPageLink = '/admin/crew';
  prefixUrl = '/';

  constructor(
    private crewService: CrewService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private translate: TranslateService,
    private toastrService: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.buildCrewForm();

    if (this.router.url.includes('/admin')) {
      this.prefixUrl = '/admin/';
    }

    const crewId = this.route.snapshot.paramMap.get('id');
    if (crewId) {
      this.prevPageLink = `${this.prefixUrl}crew/profile/${crewId}`;
      this.crewId = +crewId;
      this.pageIconClass = 'fa-solid fa-pen';
      this.pageTitle = this.translate.instant('features.addCrew.TITLE_MODIFY');
      this.loadCrew();
    }
  }

  /**
   * Return true if the user has not made any changes still unsaved
   */
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.crewForm.dirty && !this.isFormSaved) {
      return this.dialogService.confirm(
        this.translate.instant('features.addCrew.CREW_UNSAVED_CHANGES'),
        this.translate.instant('features.addCrew.CONFIRM_CANCEL')
      );
    }

    return true;
  }

  /**
   * define the form for crew entry
   */
  private buildCrewForm() {
    this.crewForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      description: [''],
      crewManagers: [[], Validators.required],
      sitecode: [this.site.code],
    });
  }

  /**
   * Load and set the crew data to the form
   */
  loadCrew() {
    if (this.crewId) {
      this.crewService
        .getCrewById(this.crewId, this.site.code)
        .pipe(takeUntil(this.destroy$))
        .subscribe((crew) => {
          this.crewForm.patchValue(crew);
          const managers = crew.managers!.map((a: any) => {
            return { id: a.objectId, displayName: a.name };
          });
          this.crewForm.controls['crewManagers'].patchValue(managers);
        });
    }
  }

  /**
   * Return to crew List
   */
  private goToCrewList() {
    if (this.crewId) {
      this.router.navigate([`${this.prefixUrl}crew/profile/${this.crewId}`]);
    } else {
      this.router.navigate([`${this.prefixUrl}crew`]);
    }
  }

  /**
   * return to crew List
   */
  cancel() {
    this.goToCrewList();
  }

  add(event: any = null) {
    event && event.preventDefault();

    this.crewForm.markAllAsTouched();

    if (this.crewForm.invalid) {
    } else {
      const crew = this.crewForm.getRawValue();
      crew.managerObjectIds = crew.crewManagers.map((a: any) => a.id);

      this.crewService
        .saveCrew(crew)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.isFormSaved = true;

          if (crew.id) {
            this.toastrService.success(
              this.translate.instant('features.crew.CREW_SUCCESSFULLY_UPDATED')
            );
          } else {
            this.toastrService.success(
              this.translate.instant('features.crew.CREW_SUCCESSFULLY_CREATED')
            );
          }

          this.goToCrewList();
        });
    }
  }

  /**
   * Delete a crew
   */
  deleteCrew() {
    this.crewService.deleteCrew(this.site.code, this.crewId).subscribe(() => {
      this.router.navigate([`${this.prefixUrl}crew`]);

      this.toastrService.success(
        this.translate.instant('features.crew.CREW_SUCCESSFULLY_DELETED')
      );
    });
  }

  confirmRemoveCrew() {
    this.dialogService
      .confirm(
        this.translate.instant('features.crew.CONFIRM_REMOVE_DIALOG.TITLE'),
        this.translate.instant(
          'features.crew.CONFIRM_REMOVE_DIALOG.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.deleteCrew();
        }
      });
  }

  /**
   * Get the form control for the crew Name
   */
  get crewNameCtrl() {
    return this.crewForm.get('name') as FormControl;
  }
}
