<mat-sidenav-container class="example-container" autosize>
    <mat-sidenav [fixedInViewport]="'fixed'" [fixedTopGap]="63" #drawer class="w-screen md:w-[750px]" mode="over"
        position="end" #sidenav>
        <button (click)="closeDrawer()" type="button"
            class="items-end md:hidden mx-4 mt-2 bg-gray-200 rounded-md p-2 inline-flex justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
        <app-equipment-detail [equipment]="selectedEquipment" [sidenav]="sidenav"
            class=" shadow bg-white flex-1 relative z-0 overflow-y-auto focus:outline-none md:order-last">
        </app-equipment-detail>
    </mat-sidenav>

    <app-page-title *ngIf="equipments" [iconClass]="pageIconClass" [title]="pageTitle + ' (' + equipments.length  + ')'"
        [routerLink]="[prevLink]" [showChevron]=true>
        <div content class="inline-flex">
            <button *ngIf="hasRightCreateEquipment" [ngClass]="{'sidenavExpanded': !isSidenavCollapse}"
                (click)="addEquipment($event)" type="button"
                class="inline-flex absolute right-[100px] sm:right-[70px] lg:right-[73px] top-[12px] sm:top-[9px] items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-800">
                <i class="fa-solid fa-plus">
                </i>
                <span class="ml-2 hidden sm:block">{{ 'features.equipment.ADD_EQUIPMENT_BUTTON' | translate }}</span>

            </button>
            <button (click)="toggleFilters($event)" type="button"
                class="sm:hidden inline-flex absolute right-[14px] top-[12px] items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gray-800">
                <i class="fa-solid fa-sliders">
                </i>
            </button>
        </div>
    </app-page-title>
    <div class="flex-1 relative z-0 flex overflow-hidden">
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none md:order-last">
            <article>

                <div class="bg-white pt-8 md:pt-0 pb-[60px]">
                    <div class="min-h-screen max-w-8xl mx-auto sm:px-6 lg:px-8">

                        <div
                            class="-mx-px  border-gray-200 grid grid-cols-2 sm:mx-0 md:grid-cols-4 lg:grid-cols-6 2xl:grid-cols-8">
                            <div *ngFor="let equipment of equipments" (click)="openDrawer(equipment)"
                                class="cursor-pointer rounded shadow m-4 group relative p-4 border-r border-b bg-gray-50 border-gray-200 sm:p-5">
                                <div
                                    class="rounded-lg border-slate-50 overflow-hidden bg-gray-200 aspect-w-1 aspect-h-1 group-hover:opacity-75">
                                    <img [src]="equipment.pictureUri || './assets/img/DockIcon.png'"
                                        alt="equipment image" class="w-full h-full object-center">
                                </div>
                                <div class="pt-2 text-center">
                                    <h3 class="text-base font-bold text-gray-900">
                                        <span
                                            [ngClass]="{'bg-red-600': equipment.isActive === false, 'bg-green-500': equipment.isActive}"
                                            class="h-3 w-3 rounded-full inline-block" aria-hidden="true">
                                        </span>
                                        {{ equipment.name }}
                                    </h3>
                                    <div class="flex flex-col items-center">
                                        <p class="text-sm text-gray-500">{{ equipment.categoryName }}</p>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div *ngIf="equipments && equipments.length === 0"
                            class="mx-4 sm:mx-auto mt-8 max-w-3xl relative block w-auto border-2 border-gray-300 border-dashed rounded-lg m-4 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <i class="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
                            <span class="mt-2 block text-sm font-medium text-gray-900"> {{
                                'features.equipment.NO_EQUIPMENT_FOUND' |
                                translate}} </span>
                        </div>

                    </div>
                </div>

            </article>
        </main>

    </div>

</mat-sidenav-container>