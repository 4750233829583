import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialUIModule } from './material-ui.module';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { RouterModule } from '@angular/router';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { WelcomeDialogComponent } from './components/welcome-dialog/welcome-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { FileBrowserComponent } from './components/file-browser/file-browser.component';
import { ImagePickerComponent } from './components/image-picker/image-picker.component';
import { UserAutocompleteComponent } from './components/user-autocomplete/user-autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'primeng/carousel';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ProfileDetailComponent } from '../features/user-profile/components/profile-detail/profile-detail.component';
import { ProfileDetailInfoComponent } from '../features/profile/components/profile-detail/profile-detail.component';
import { ReservationDetailComponent } from '../features/reservation/components/reservation-detail/reservation-detail.component';
import { MbscModule } from '@mobiscroll/angular';
import { SearchFilterPipe } from 'src/app/core/pipes/search-filter.pipe';
import { EquipmentComponent } from '../features/equipment/equipment.component';
import { EquipmentDetailComponent } from '../features/equipment/components/equipment-detail/equipment-detail.component';
import { EquipmentListComponent } from '../features/equipment/components/equipment-list/equipment-list.component';
import { EquipmentSearchComponent } from '../features/equipment/components/equipment-search/equipment-search.component';
import { RecurringDateComponent } from '../features/reservation/components/reservation-add/components/reservation-add-detail/components/recurring-date/recurring-date.component';
import { AppointmentConfirmationDialogComponent } from './components/appointment-confirmation-dialog/appointment-confirmation-dialog.component';

@NgModule({
  declarations: [
    PageTitleComponent,
    ConfirmDialogComponent,
    WelcomeDialogComponent,
    AppointmentConfirmationDialogComponent,
    FileBrowserComponent,
    ImagePickerComponent,
    UserAutocompleteComponent,
    ProfileDetailComponent,
    ProfileDetailInfoComponent,
    ReservationDetailComponent,
    SearchFilterPipe,
    EquipmentComponent,
    EquipmentDetailComponent,
    EquipmentListComponent,
    EquipmentSearchComponent,
    RecurringDateComponent,
  ],
  imports: [
    MbscModule,
    CommonModule,
    MaterialUIModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    MbscModule,
    ReservationDetailComponent,
    MaterialUIModule,
    RouterModule,
    PageTitleComponent,
    TranslateModule,
    WelcomeDialogComponent,
    AppointmentConfirmationDialogComponent,
    FileBrowserComponent,
    ImagePickerComponent,
    UserAutocompleteComponent,
    ProfileDetailComponent,
    ProfileDetailInfoComponent,
    CarouselModule,
    CalendarModule,
    ButtonModule,
    TooltipModule,
    SearchFilterPipe,
    EquipmentComponent,
    EquipmentDetailComponent,
    EquipmentListComponent,
    EquipmentSearchComponent,
    RecurringDateComponent,
  ],
})
export class SharedModule {}
