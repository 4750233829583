import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SitenavMenuComponent } from './components/sitenav-menu/sitenav-menu.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { TopSearchComponent } from './components/top-search/top-search.component';
import { SharedModule } from '../shared/shared.module';
import { SiteMenuComponent } from './components/site-menu/site-menu.component';

@NgModule({
  declarations: [
    SitenavMenuComponent,
    ProfileMenuComponent,
    TopSearchComponent,
    SiteMenuComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    SitenavMenuComponent,
    ProfileMenuComponent,
    TopSearchComponent,
    SharedModule,
    SiteMenuComponent,
  ],
})
export class SdtCoreModule {}
