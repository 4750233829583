<div class="relative">
    <div
        class="px-4 py-2.5 sm:px-6 bg-yellow-default flex justify-between items-center shadow flex-wrap sm:flex-nowrap">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
            <i class="fa-solid fa-circle-info mr-2"></i>
            {{ 'features.selectEquipment.SELECT_EQUIPMENT' | translate}}
        </h3>
    </div>

    <div
        class="bg-gray-50 px-4 sm:px-6 lg:px-8 border border-gray-300 border-b-1 border-t-0 border-l-0 border-r-0 pb-2">
        <ul role="list" class="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-3 xl:grid-cols-3">
            <li class="relative col-span-1 flex rounded-md">
                <mat-form-field [style.width.%]="100">
                    <mat-label>{{ 'features.selectEquipment.SEARCH' | translate}}</mat-label>
                    <input [(ngModel)]="searchText" (keyup)="term.next($any($event).target.value)" variant="outlined"
                        matInput>
                    <i *ngIf="searchText" class="cursor-pointer fa-solid fa-xmark text-gray-500 absolute right-5 top-2"
                        (click)="clearSearchText($event)"></i>
                </mat-form-field>
            </li>
            <li class="relative col-span-1 flex rounded-md">
                <mat-form-field [style.width.%]="100">
                    <mat-label>{{ 'features.selectEquipment.CATEGORY' | translate}}</mat-label>
                    <mat-select (selectionChange)="searchEquipments()" [(ngModel)]="categoryId">
                        <mat-option *ngFor="let cat of categories" [value]="cat.id">
                            {{cat.categoryName}}
                        </mat-option>
                    </mat-select>
                    <i *ngIf="categoryId" class="cursor-pointer fa-solid fa-xmark text-gray-500 absolute right-5 top-2"
                        (click)="clearCategory($event)">
                    </i>
                </mat-form-field>
            </li>
        </ul>

    </div>

    <div class="whitespace-pre-wrap h-[293px] md:h-[413px] overflow-y-auto pb-[60px]" *ngIf="equipments">
        <cdk-accordion class="example-accordion">
            <cdk-accordion-item *ngFor="let item of equipments; let index = index;" #accordionItem="cdkAccordionItem"
                class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
                [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
                <div class="example-accordion-item-header"
                    [ngClass]="{'selected': selectedItem && selectedItem.id === item.id}"
                    (click)="selectEquipment(item)">
                    <div>
                        <img class="h-10 w-14 rounded inline-block mr-2"
                            [src]="item.pictureUri || './assets/img/DockIcon.png'" alt="">
                        {{ item.name }}
                    </div>

                    <span class="example-accordion-item-description"
                        (click)="accordionItem.toggle(); toggleAccordion($event)">
                        <i class="fa-solid fa-circle-info"></i>
                        {{ 'features.selectEquipment.DETAIL' | translate}}
                    </span>
                </div>
                <div class="example-accordion-item-body" role="region"
                    [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                    [attr.aria-labelledby]="'accordion-header-' + index">
                    {{ item.description || 'N/A' }}
                </div>
            </cdk-accordion-item>
        </cdk-accordion>
    </div>

    <div *ngIf="equipments && equipments.length === 0">
        <div
            class="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg m-12 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <i class="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
            <span class="mt-2 block text-sm font-medium text-gray-900"> {{ 'features.addReservation.NO_EQUIPMENT' |
                translate}} </span>
            <span class="mt-2 block text-sm font-medium text-gray-500"> {{
                'features.addReservation.ADD_EQUIPMENT_CONTACT_MANAGWER' | translate}}
            </span>

        </div>
    </div>

    <div class="bg-gray-50 px-4 py-3 sm:px-6 text-right sticky bottom-0 left-0">

        <button (click)=" onNoClick()"
            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            {{ 'labels.CANCEL' | translate}}
        </button>

        <button (click)="save()"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600">
            {{ 'labels.SAVE' | translate}}
        </button>

    </div>
</div>