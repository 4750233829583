import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  pageIconClass = "fa-solid fa-circle-question";
  mailAPD: string = `mailto:apd.services@agnicoeagle.com?subject=SCHEDULE DELIVERY TOOL: Help / Feedback`;

  constructor() { }

  ngOnInit(): void {
  }

}
