import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { RESERVATION_STATUS } from '@app/constants/reservation_status.constants';

@Component({
  selector: 'app-reservation-calendar-legend',
  templateUrl: './reservation-calendar-legend.component.html',
  styleUrls: ['./reservation-calendar-legend.component.scss'],
})
export class ReservationCalendarLegendComponent implements OnInit, OnChanges {
  @Input() toggleReservationLegend!: any;

  isShowLegend = false;
  reservationStatus = RESERVATION_STATUS;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.toggleReservationLegend &&
      !changes.toggleReservationLegend.firstChange
    ) {
      this.toggleLegend();
    }
  }

  toggleLegend() {
    this.isShowLegend = !this.isShowLegend;
  }
}
