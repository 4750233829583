import { Component, OnInit, ElementRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { SdtBaseComponent } from '../../../features/SdtBaseComponent';
import { SiteService } from '@app/services/data/site.service';
import { Site } from 'src/app/data';
import { Router } from '@angular/router';
import { MenuService } from '@app/services/common/menu.service';
import { DialogService } from '@app/services/common/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { UserProfile } from 'src/app/data';
import { UserProfileService } from '@app/services/data/user-profile.service';

@Component({
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(document:click)': 'onClick($event)',
  },
  selector: 'app-site-menu',
  templateUrl: './site-menu.component.html',
  styleUrls: ['./site-menu.component.scss'],
  animations: [
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(0.25rem)' }),
        animate(
          '200ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '150ms ease-in',
          style({ opacity: 0, transform: 'translateY(0.25rem)' })
        ),
      ]),
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate(
          '100ms ease-in',
          style({ opacity: 0, transform: 'scale(.95)' })
        ),
      ]),
    ]),
  ],
})
export class SiteMenuComponent extends SdtBaseComponent implements OnInit {
  isSiteMenu = false;
  userProfile!: UserProfile;

  constructor(
    private siteService: SiteService,
    private router: Router,
    private menuService: MenuService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private userProfileService: UserProfileService,
    private _eref: ElementRef
  ) {
    super();

    // Update list menu when a site is created or updated
    this.menuService.getModifySites().subscribe(() => {
      this.getAllSites();
    });

    this.menuService.getModifyDefaultSite().subscribe(() => {
      const siteStr = localStorage.getItem('SDT_SITE');

      if (siteStr) {
        try {
          this.site = JSON.parse(siteStr);
        } catch (error) {}
      }
    });
  }

  ngOnInit(): void {
    // We will set LaRonde Site as the default site for the application until we have a user profile page
    this.getDefaultSite();

    // Retrieve all Sites for Site Menu in Header
    this.getAllSites();

    this.loadMyProfile();
  }

  toggleSiteMenu() {
    this.isSiteMenu = !this.isSiteMenu;
  }

  confirmUpdateSite(site: Site) {
    if (this.site.id !== site.id) {
      this.dialogService
        .confirm(
          this.translate.instant(
            'features.site.SITE_CONFIRM_CHANGE_DIALOG.TITLE'
          ),
          this.translate.instant(
            'features.site.SITE_CONFIRM_CHANGE_DIALOG.DESCRIPTION'
          )
        )
        .subscribe((result: any) => {
          if (result) {
            this.udpateSite(site);
          } else {
            this.toggleSiteMenu();
          }
        });
    } else {
      this.toggleSiteMenu();
    }
  }

  loadMyProfile() {
    this.userProfileService
      .getMyUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: any) => {
        this.userProfile = userProfile;
        localStorage.setItem('SDT_USER_PROFILE', JSON.stringify(userProfile));
        this.setDefaultLanguage(this.userProfile.language);
      });
  }

  setDefaultLanguage(lang: number) {
    const language: string = lang === 0 ? 'en' : 'fr';
    this.translate.use(language).toPromise();
  }

  udpateSite(site: Site) {
    this.site = site;
    this.siteMenuService.updateSite(site);
    this.menuService.updateDefaultSite(true);
    this.isSiteMenu = false;
    this.router.navigate(['/']);
    setTimeout(() => {
      this.router.navigate(['/reservation']);
    }, 100);
  }

  getAllSites() {
    this.siteService
      .getSites(0, 0)
      .pipe(takeUntil(this.destroy$))
      .subscribe((resp: any) => {
        this.sites = resp.data;

        const siteStr = localStorage.getItem('SDT_SITE');

        if (siteStr) {
          try {
            this.site = JSON.parse(siteStr);
          } catch (error) {}
        } else {
          if (this.sites && this.sites.length > 0) {
            this.site = this.sites[0];
            this.udpateSite(this.site);
          }
        }
      });
  }

  getDefaultSite() {
    const localStorageSite = localStorage.getItem('SDT_SITE');

    if (!localStorageSite) {
      this.siteService.getSites().subscribe((result) => {
        if (result && result.data && result.data.length > 0) {
          // User has set a default Site in his profile
          this.userProfileService
            .getMyUserProfile()
            .pipe(takeUntil(this.destroy$))
            .subscribe((userProfile: UserProfile) => {
              if (userProfile && userProfile.siteId !== 0) {
                this.siteService
                  .getSiteById(userProfile.siteId)
                  .subscribe((data) => {
                    this.siteMenuService.updateSite(data);
                    this.menuService.updateDefaultSite(true);
                    this.site = data;
                  });
                // User has not set a default Site in his profile
              } else {
                this.siteService
                  .getSiteById(result.data[0].id)
                  .subscribe((data) => {
                    this.siteMenuService.updateSite(data);
                    this.menuService.updateDefaultSite(true);
                    this.site = data;
                  });
              }
            });
        }
      });
    }
  }

  onClick(event: Event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.isSiteMenu = false;
    }
  }
}
