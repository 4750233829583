import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  RoleService,
  RoleUpdateRequest,
} from '@app/services/data/role.service';
import { merge, startWith, Subject, takeUntil, switchMap, map } from 'rxjs';
import { Role, PaginatedItems } from 'src/app/data';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { SdtBaseComponent } from '../../SdtBaseComponent';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
})
export class RoleComponent extends SdtBaseComponent implements AfterViewInit {
  displayedColumns: string[] = ['roleName', 'description'];
  dataSource: Role[] = [];

  @ViewChild(MatTable)
  table!: MatTable<Role>;

  pageTitle = this.translate.instant('features.role.TITLE_HOME');

  pageIconClass = 'fa-solid fa-folder-user';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  totalCount: number = 0;
  isLoadingResults = true;

  constructor(
    private roleService: RoleService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.loadRoles();
  }

  /**
   * Load categories with paginator data.
   */
  loadRoles() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.roleService.getRoles(
            this.site.code,
            this.paginator.pageSize,
            this.paginator.pageIndex
          );
        }),
        map((resp: PaginatedItems<Role>) => {
          this.isLoadingResults = false;
          this.totalCount = resp.count;
          return resp.data;
        })
      )
      .subscribe({
        next: (data) => {
          this.dataSource = data;
        },
        error: (err: HttpErrorResponse) => {
          this.isLoadingResults = false;
        },
      });
  }
  /**
   * Redirect to add role compenent
   */
  addRole() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  showRole(role: Role) {
    if (role) {
      this.router.navigate(['modify', role.id], { relativeTo: this.route });
    }
  }
}
