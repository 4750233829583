import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ROLES } from '@app/constants/roles.constants';
import { DialogService } from '@app/services/common/dialog.service';
import { UserRoleService } from '@app/services/common/user-role.service';
import { EquipmentService } from '@app/services/data/equipment.service';
import { ReservationService } from '@app/services/data/reservation.service';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { MbscDatepickerOptions, localeEn, localeFr } from '@mobiscroll/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentConfirmationDialogComponent } from '@shared/components/appointment-confirmation-dialog/appointment-confirmation-dialog.component';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, takeUntil } from 'rxjs';
import { ReservationType } from 'src/app/data/ReservationType.model';
import {
  Equipment,
  Reservation,
  ReservationStatus,
  UserProfile,
} from 'src/app/data/index';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';
import { ReservationEquipmentSelectComponent } from '../reservation-equipment-select/reservation-equipment-select.component';
import { RecurringDateComponent } from './components/recurring-date/recurring-date.component';

@Component({
  selector: 'app-reservation-add-detail',
  templateUrl: './reservation-add-detail.component.html',
  styleUrls: ['./reservation-add-detail.component.scss'],
})
export class ReservationAddDetailComponent
  extends SdtBaseComponent
  implements OnInit, OnChanges
{
  selectedStartDate: any;
  selectedEndDate: any;
  selectedEquipment!: Equipment;
  equipments!: Equipment[];
  tabIndex = 0;
  files: any = [];
  documents: any[] = [];
  reservation: Reservation | null = null;

  docks = [
    { id: 1, name: 'dock 1' },
    { id: 2, name: 'dock 2' },
    { id: 3, name: 'dock 3' },
    { id: 4, name: 'dock 4' },
    { id: 5, name: 'dock 5' },
    { id: 6, name: 'dock 6' },
    { id: 7, name: 'dock 7' },
    { id: 8, name: 'dock 8' },
    { id: 9, name: 'dock 9' },
    { id: 10, name: 'dock 10' },
    { id: 11, name: 'dock 11' },
  ];

  @ViewChild(RecurringDateComponent) recurringDateComponent!: any;

  @Output() updateLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() equipment: EventEmitter<Equipment> = new EventEmitter<Equipment>();
  @Output() startDate: EventEmitter<string> = new EventEmitter<string>();
  @Output() endDate: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectUser: EventEmitter<any> = new EventEmitter<any>();
  @Output() recurringRule: EventEmitter<any> = new EventEmitter<any>();
  @Output() recurring: EventEmitter<any> = new EventEmitter<any>();
  @Output() reccurenceExclusions: EventEmitter<any> = new EventEmitter<any>();
  @Input() dateUpdated!: any;
  @Input() isReservationOverlap!: boolean;

  datepickerOptionsEndDate: MbscDatepickerOptions = {
    moment: moment,
    returnFormat: 'moment',
    select: 'date',
    controls: ['calendar', 'time'],
    stepMinute: 5,
    timeFormat: 'HH:mm',
    onClose: (event, inst) => {
      const formValues = this.reservationform.getRawValue();
      let startDate = moment(formValues.startDate);
      let endDate = moment(formValues.endDate);

      if (event.valueText !== '' && startDate > endDate) {
        let startDate = moment(event.valueText).add(-1, 'hour');

        this.reservationform.controls['startDate'].patchValue(startDate);
      }
    },
  };

  datepickerOptionsStartDate: MbscDatepickerOptions = {
    moment: moment,
    returnFormat: 'moment',
    select: 'date',
    controls: ['calendar', 'time'],
    stepMinute: 5,
    timeFormat: 'HH:mm',
    onClose: (event, inst) => {
      const formValues = this.reservationform.getRawValue();
      let startDate = moment(formValues.startDate);
      let endDate = moment(formValues.endDate);

      if (event.valueText !== '' && startDate > endDate) {
        let endDate = moment(event.valueText).add(1, 'hour');

        this.reservationform.controls['endDate'].patchValue(endDate);
      }
    },
  };

  locale = this.profile?.language === 0 ? localeEn : localeFr;
  user = this.translate.instant('labels.USER');
  calendarSelectedDate: any = new Date();

  tempEvent: any;
  isFormSaved: boolean = false;
  reservationform!: FormGroup;
  reservationId!: number;
  isEdit = false;
  selectedUser!: UserProfile | null;
  reccuringRule: string = '';
  isRecurring = false;

  isAdmin = false;
  equipmentId: number | null = null;
  reservationStatus: ReservationStatus[] = [];
  reservationTypes: ReservationType[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private equipmentService: EquipmentService,
    private toastrService: ToastrService,
    private reservationService: ReservationService,
    private userProfileService: UserProfileService,
    private roleUserService: UserRoleService
  ) {
    super();
  }

  async ngOnInit() {
    const equipmentId = this.route.snapshot.paramMap.get('equipmentId');
    const reservationId = this.route.snapshot.paramMap.get('id');

    this.selectedUser = this.profile;

    if (!this.roleUserService.getAccessToken()) {
      await this.roleUserService.fetchAccessToken();
    }

    const roles = this.roleUserService.getRoles() || [];
    const siteManagerRole = ROLES.SITE_MANAGER.replace(
      '{siteId}',
      this.site.id
    );
    const sitePlanerRole = ROLES.SITE_PLANER.replace('{siteId}', this.site.id);
    const requiredRoles = [siteManagerRole, sitePlanerRole, ROLES.ADMIN];

    if (requiredRoles.some((r) => roles.includes(r))) {
      this.isAdmin = true;
    }

    this.selectUser.emit(this.selectedUser);

    this.buildReservationForm();

    this.loadEquipments();
    this.loadReservationStatus();
    this.loadReservationTypes();

    if (reservationId) {
      this.isEdit = true;
      this.reservationId = +reservationId;
      this.loadReservation(this.reservationId);
    }

    if (equipmentId) {
      this.equipmentId = +equipmentId;

      this.equipmentService
        .getEquipmentById(+equipmentId, this.site.code)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          this.updateEquipment(result);
        });
    }

    this.reservationform.get('user')?.valueChanges.subscribe((val) => {
      if (val && val.length > 0) {
        if (isNaN(val[0].id)) {
          this.getUserByObjectId(val[0].id);
        } else {
          this.getUser(val[0].id);
        }
      } else {
        this.selectedUser = null;
        this.selectUser.emit(null);
      }
    });

    this.reservationform.get('startDate')?.valueChanges.subscribe((val) => {
      const startDate = moment(val).format('YYYY-MM-DD[T]HH:mm');

      this.startDate.emit(startDate);
    });

    this.reservationform.get('endDate')?.valueChanges.subscribe((val) => {
      const endDate = moment(val).format('YYYY-MM-DD[T]HH:mm:ss');
      this.endDate.emit(endDate);
    });

    if (this.isEdit === false) {
      let startDate = moment();
      let endDate = startDate.clone().add(1, 'hour');

      this.reservationform.controls['startDate'].patchValue(
        startDate.format('YYYY-MM-DD[T]HH:mm:ss')
      );
      this.reservationform.controls['endDate'].patchValue(
        endDate.format('YYYY-MM-DD[T]HH:mm:ss')
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dateUpdated && changes.dateUpdated.currentValue) {
      let startDate = moment(changes.dateUpdated.currentValue.startDate).format(
        'YYYY-MM-DD[T]HH:mm:ss'
      );

      let endDate = moment(changes.dateUpdated.currentValue.endDate).format(
        'YYYY-MM-DD[T]HH:mm:ss'
      );

      console.log('startDate', startDate);
      console.log('endDate', endDate);

      this.reservationform.controls['startDate'].patchValue(startDate);
      this.reservationform.controls['endDate'].patchValue(endDate);
    }
  }

  onDockChange(event: any) {
    this.getEquipment(event?.value);
  }

  filesToUpload(files: any) {
    this.files = files;
  }

  setMyUser() {
    this.reservationform.controls['user'].patchValue([
      {
        id: this.profile.objectId,
        displayName: this.profile.name,
      },
    ]);
  }

  setUser(reservation: Reservation) {
    this.reservationform.controls['user'].patchValue([
      {
        id: reservation.userId,
        displayName: reservation.userName,
      },
    ]);
  }

  /**
   * Return true if the user has not made any changes still unsaved
   */
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.reservationform.dirty && !this.isFormSaved) {
      return this.dialogService.confirm(
        this.translate.instant('features.addReservation.CREW_UNSAVED_CHANGES'),
        this.translate.instant('features.addReservation.CONFIRM_CANCEL')
      );
    }

    return true;
  }

  /**
   * define the form for reservation entry
   */
  private buildReservationForm() {
    this.reservationform = this.formBuilder.group({
      id: [''],
      equipmentId: ['', Validators.required],
      type: ['', Validators.required],
      equipmentName: [''],
      name: [''],
      user: [''],
      eventName: [''],
      rRule: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      supplier: [''],
      purcharseOrders: ['', Validators.required],
      hasHazardousMaterial: [false],
      carrier: ['', Validators.required],
      carrierEmails: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^([\w-\.]+)@([\w-\.]+\.)+([\w-\.]+)+([;]([\w-\.]+)@([\w-\.]+\.)+([\w-\.]+))*$/
          ),
        ],
      ],
      carrierNo: ['', Validators.required],
      typeOfTrailer: ['', Validators.required],
      palletsInfo: ['', Validators.required],
      status: ['', Validators.required],
      note: [''],
      isClosed: [false],
      billOfLanding: [''],
      files: [''],
      sitecode: [this.site.code],
    });
  }

  get supplierCtrl() {
    return this.reservationform.get('supplier') as FormControl;
  }

  get purcharseOrdersCtrl() {
    return this.reservationform.get('purcharseOrders') as FormControl;
  }

  get hasHazardousMaterialCtrl() {
    return this.reservationform.get('hasHazardousMaterial') as FormControl;
  }

  get carrierCtrl() {
    return this.reservationform.get('carrier') as FormControl;
  }

  get carrierEmailsCtrl() {
    return this.reservationform.get('carrierEmails') as FormControl;
  }

  get carrierNoCtrl() {
    return this.reservationform.get('carrierNo') as FormControl;
  }

  get typeOfTrailerCtrl() {
    return this.reservationform.get('typeOfTrailer') as FormControl;
  }

  get palletsInfoCtrl() {
    return this.reservationform.get('palletsInfo') as FormControl;
  }

  /**
   * Get the form control for the Description
   */
  get descriptionCtrl() {
    return this.reservationform.get('descritpion') as FormControl;
  }

  /**
   * Get the form control for the category Name
   */
  get equipmentCtrl() {
    return this.reservationform.get('equipmentId') as FormControl;
  }

  /**
   * Get the form control for the reservation type
   */
  get reservationTypeCtrl() {
    return this.reservationform.get('type') as FormControl;
  }

  /**
   * Get the form control for the Start Date
   */
  get startDateCtrl() {
    return this.reservationform.get('startDate') as FormControl;
  }

  /**
   * Get the form control for the End Date
   */
  get endDateCtrl() {
    return this.reservationform.get('endDate') as FormControl;
  }

  /**
   * Get the form control for the rRule
   */
  get rRuleCtrl() {
    return this.reservationform.get('rRule') as FormControl;
  }

  /**
   * Get the form control for the status
   */
  get statusCtrl() {
    return this.reservationform.get('status') as FormControl;
  }

  /**
   * Get the form control for the rRule
   */
  get noteCtrl() {
    return this.reservationform.get('note') as FormControl;
  }

  /**
   * Get the form control for the rRule
   */
  get appointmentClosedCtrl() {
    return this.reservationform.get('isCLosed') as FormControl;
  }

  selectEquipment() {
    const dialogRef = this.dialog.open(ReservationEquipmentSelectComponent, {
      width: '750px',
      height: '650px',
      panelClass: 'equipment-select',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateEquipment(result);
      }
    });
  }

  updateEquipment(equipment: Equipment) {
    this.selectedEquipment = equipment;
    this.reservationform.controls['equipmentId'].patchValue(
      this.selectedEquipment.id
    );

    this.getEquipment(equipment.id!);
  }

  get nameCtrl() {
    return this.reservationform.get('name') as FormControl;
  }

  monthChange(e: Event) {}

  onDeleteClick() {}

  loadReservationStatus() {
    this.reservationService
      .getReservationsStatus(this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: ReservationStatus[]) => {
        this.reservationStatus = status;

        if (!this.isEdit) {
          this.reservationform.controls['status'].patchValue(5);
        }
      });
  }

  loadEquipments() {
    this.equipmentService
      .getEquipments(this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((equipments: any) => {
        this.equipments = equipments.data;

        this.equipments.sort((a: Equipment, b: Equipment) => a.id! - b.id!);
      });
  }

  loadReservationTypes() {
    this.reservationService
      .getReservationTypes(this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((types: ReservationType[]) => {
        this.reservationTypes = types;

        if (!this.isEdit) {
          this.reservationform.controls['type'].patchValue(1);
        }
      });
  }

  loadReservation(reservationId: number) {
    this.reservationService
      .getReservationsById(this.site.code, reservationId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        result.startDate = moment(result.startDate).format(
          'YYYY-MM-DD[T]HH:mm:ss'
        );
        result.endDate = moment(result.endDate).format('YYYY-MM-DD[T]HH:mm:ss');
        this.reservationform.patchValue(result);

        this.setUser(result);

        this.getUser(result.userId!);

        // get equipment related to the reservation
        this.getEquipment(result.equipmentId!);

        this.reservation = result;
        this.documents = result?.documents;

        this.isRecurring = result.rRule ? true : false;
      });
  }

  confirmSave(isSendCarrierEmail: boolean) {
    const isSentEmail = this.isEdit ? isSendCarrierEmail : true;

    if (this.isReservationOverlap) {
      this.dialogService
        .confirm(
          'New Appointment Overlap',
          'New appointment overlaps with a current appointment, \nDo you want to continue ?'
        )
        .subscribe((result: any) => {
          if (result) {
            this.save(isSentEmail);
          } else {
          }
        });
    } else {
      this.save(isSentEmail);
    }
  }

  save(isSendCarrierEmail: boolean) {
    if (this.isRecurring) {
      this.recurringDateComponent.getCustomRule();
    }

    if (this.reservationform.invalid) {
    } else {
      this.updateLoading.emit(true);

      const formValues = this.reservationform.getRawValue();

      const reservation: Reservation = {
        startDate: moment(formValues.startDate).format('YYYY-MM-DD[T]HH:mm:ss'),
        endDate: moment(formValues.endDate).format('YYYY-MM-DD[T]HH:mm:ss'),
        type: formValues.type,
        objectId: this.profile!.objectId,
        eventName: '',
        description: '',
        id: this.reservationId,
        supplier: formValues.supplier,
        purcharseOrders: formValues.purcharseOrders,
        hasHazardousMaterial: formValues.hasHazardousMaterial,
        carrier: formValues.carrier,
        carrierEmails: formValues.carrierEmails,
        carrierNo: formValues.carrierNo,
        typeOfTrailer: formValues.typeOfTrailer,
        palletsInfo: formValues.palletsInfo,
        billOfLanding: formValues.billOfLanding,
        equipmentName: formValues.equipmentName,
        equipmentId: formValues.equipmentId,
        files: [],
        rRule: '',
        status: formValues.status,
        note: formValues.note,
        isClosed: formValues.isClosed,
        isSendCarrierEmail: isSendCarrierEmail,
      };

      let payload = new FormData();

      payload.append('startDate', reservation.startDate);
      payload.append('endDate', reservation.endDate);
      payload.append('type', reservation.type!.toString());
      payload.append('objectId', reservation.objectId || '');
      payload.append('eventName', 'test');
      payload.append('description', 'test');
      payload.append('supplier', reservation.supplier!);
      payload.append('purcharseOrders', reservation.purcharseOrders!);
      payload.append(
        'hasHazardousMaterial',
        JSON.stringify(reservation.hasHazardousMaterial!)
      );
      payload.append('carrier', reservation.carrier!);
      payload.append('CarrierEmails', reservation.carrierEmails!);
      payload.append('carrierNo', reservation.carrierNo!);
      payload.append('typeOfTrailer', reservation.typeOfTrailer!);
      payload.append('palletsInfo', reservation.palletsInfo!);
      payload.append('billOfLanding', reservation.billOfLanding!);
      payload.append('equipmentName', reservation.equipmentName!);
      payload.append('status', reservation.status!.toString());
      payload.append('note', reservation.note!);
      payload.append('isClosed', JSON.stringify(reservation.isClosed!));
      payload.append(
        'isSendCarrierEmail',
        JSON.stringify(reservation.isSendCarrierEmail!)
      );
      // payload.append('equipmentId', reservation.equipmentId!.toString());

      this.files.map((file: any) => {
        payload.append('files', file);
      });

      if (this.reservationId) {
        this.equipmentService
          .updateReservation(
            this.site.code,
            reservation.equipmentId!,
            reservation.id!,
            payload
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (result: Reservation) => {
              this.toastrService.success(
                this.translate.instant(
                  this.reservationId
                    ? 'features.addReservation.UPDATE_RESERVATION_SUCCESS'
                    : 'features.addReservation.CREATE_RESERVATION_SUCCESS'
                )
              );

              this.updateLoading.emit(false);
              this.goToReservationList();
              this.showConfirmSavedDialog(result?.uniqueCode!);
            },
            error: (error) => {
              this.updateLoading.emit(false);
            },
          });
      } else {
        this.equipmentService
          .createReservation(this.site.code, formValues.equipmentId!, payload)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (result: Reservation) => {
              this.toastrService.success(
                this.translate.instant(
                  this.reservationId
                    ? 'features.addReservation.UPDATE_RESERVATION_SUCCESS'
                    : 'features.addReservation.CREATE_RESERVATION_SUCCESS'
                )
              );

              this.updateLoading.emit(false);
              this.goToReservationList();
              this.showConfirmSavedDialog(result?.uniqueCode!);
            },
            error: (error) => {
              this.updateLoading.emit(false);
            },
          });
      }
    }
  }

  getUser(id: number) {
    this.userProfileService
      .getUserProfile(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.selectedUser = result;
        this.selectUser.emit(this.selectedUser);
      });
  }

  getUserByObjectId(objectId: string) {
    this.userProfileService
      .getUserProfileByObjectId(objectId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.selectedUser = result;
        this.selectUser.emit(this.selectedUser);
      });
  }

  getEquipment(equipmentId: number) {
    this.equipmentService
      .getEquipmentById(equipmentId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.selectedEquipment = result;

        // this.equipmentCtrl.patchValue(equipmentId);
        this.equipment.emit(this.selectedEquipment);
      });
  }

  cancel() {
    this.goToReservationList();
  }

  /**
   * Return to reservation List
   */
  private goToReservationList() {
    if (this.isAdmin) {
      if (this.equipmentId) {
        this.router.navigate([
          this.router.url.split('/').slice(0, -2).join('/'),
        ]);
      } else {
        this.router.navigate(['/admin/reservation']);
      }
    } else {
      if (this.equipmentId) {
        this.router.navigate([
          this.router.url.split('/').slice(0, -2).join('/'),
        ]);
      } else {
        this.router.navigate(['/reservation']);
      }
    }
  }

  onTabChanged(event: any) {
    this.tabIndex = event.index;
  }

  onRecurringRuleChange(event: any) {
    this.reccuringRule = event;
    this.recurringRule.emit(event);
  }

  onRecurringChange(event: any) {
    this.recurring.emit(event);
  }

  showConfirmSavedDialog(uniqueId: number) {
    const dialogRef = this.dialog.open(AppointmentConfirmationDialogComponent, {
      width: '390px',
      height: '260px',
      panelClass: 'confirm-saved-dialog',
      disableClose: true,
      data: { uniqueId: uniqueId },
    });
  }
}
